import shopifyGenericDefaults from '../shopify-generic/config.js';
import { addToCart } from './cart.js';

const baseFitmentFields = ['Year', 'Make', 'Model', 'Submodel'];
const extraFitmentFields = ['Drive'];
const wheelsFields = ['wheel_diameter', 'wheel_width', 'wheel_bolt_pattern'];
const tiresFields = ['tire_width', 'tire_aspect', 'tire_rim'];

const cultureCode = window.Shopify?.routes?.root.slice(0, -1) ?? '';

const categorySelectionPageUrl = `${cultureCode}/collections`;
const brandSelectionPageUrl = `${cultureCode}/pages/brands`;
const wheelsCollectionUrl = `${cultureCode}/collections/wheels-1`;
const tiresCollectionUrl = `${cultureCode}/collections/tires`;

const isOnHomePage = window.location.pathname === cultureCode;

const isAllProductsWheels = () => {
  return window.Convermax.getSearchResponse()
    ?.facets.get('FitmentType')
    ?.values.find((v) => v.value === 'Wheel');
};
const isAllProductsTires = () => {
  return window.Convermax.getSearchResponse()
    ?.facets.get('FitmentType')
    ?.values.find((v) => v.value === 'Tire');
};

const getActiveCurrency = () => globalThis.Shopify.currency?.active;

function formatPrice(price) {
  if (!price) {
    return '';
  }

  const activeCurrency = getActiveCurrency();
  const locale = `${window.Shopify.locale.split('-')[0]}-${window.Shopify.country}`;

  const num = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: activeCurrency,
    currencyDisplay: 'narrowSymbol',
  }).format(price);
  return `${num} ${activeCurrency}`;
}

const getFitmentSearchTitle = () =>
  window.location.pathname === categorySelectionPageUrl
    ? 'Categories'
    : window.location.pathname === brandSelectionPageUrl
      ? 'Brands'
      : 'Parts';

window.Convermax.addToCart = addToCart;

export default {
  includes: ['shopify-generic'],
  ...shopifyGenericDefaults,
  defaultView: 'grid-3',
  SearchRequestDefaults: {
    pageSize: 36,
  },
  fitmentSearch: {
    baseFields: baseFitmentFields,
    extraFields: extraFitmentFields,
    categorySelectionPages: [
      { pathname: categorySelectionPageUrl, field: 'category' },
      { pathname: brandSelectionPageUrl, field: 'vendor' },
    ],
    categorySelectionPageRedirect: true,
    isVehicleSelectionIsolated: true,
    getFitmentSearchTitle,
  },
  product: {
    ...shopifyGenericDefaults.product,
    formatPrice,
  },
  pagination: {
    edgeRange: 0,
    centralRange: 2,
    disableDelimeters: true,
  },
  facets: {
    rangedFacet: [{ fields: ['wheel_bore'], step: '1/10//1', slider: { mode: 'LowerBound' } }],
  },
  Widgets: [
    ...shopifyGenericDefaults.Widgets,
    {
      name: 'SearchBox_tab',
      type: 'SearchBoxDialogButton',
      location: {
        selector: '.cm_tab-content.cm_brand-search',
        class: 'cm_search-box-root__tab',
      },
      disableDropdown: true,
      template: 'searchBox/dialogButtonAdaptable',
    },
    {
      name: 'HeaderVehicleWidget_tab',
      type: 'VehicleWidget',
      location: '.cm_tab-content.cm_vehicle-search',
      isAlwaysColumnLayout: isOnHomePage,
      template: 'fitmentSearch/homeVehicleWidget',
      columnBreakpoint: 680,
    },
    {
      name: 'WheelPanel_tab',
      type: 'RequestPanel',
      location: '.cm_tab-content.cm_wheel-search',
      template: 'HomeWheelTireBar',
      isAutoRedirectDisabled: !isOnHomePage,
      needInitRequest: true,
      redirectUrl: wheelsCollectionUrl,
      selectFields: wheelsFields,
      shouldBeIsolated: () => !isAllProductsWheels(),
    },
    {
      name: 'TirePanel_tab',
      type: 'RequestPanel',
      location: '.cm_tab-content.cm_tire-search',
      template: 'HomeWheelTireBar',
      isAutoRedirectDisabled: !isOnHomePage,
      needInitRequest: true,
      redirectUrl: tiresCollectionUrl,
      selectFields: tiresFields,
      shouldBeIsolated: () => !isAllProductsTires(),
    },
  ],
};
