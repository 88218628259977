
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function SearchPageRT () {
    return _createElement('div', { 'className': 'clear-self cm_SearchPage' }, [this.SearchHeader(function () {
            return _createElement('div', { 'className': 'cm_SearchHeader' }, [this.facetDialogButton(function () {
                    return _createElement('div', { 'className': 'cm_facetDialogButton' }, [this.dialogButton(function () {
                            return _createElement('div', { 'className': 'cm_button cm_button__primary cm_dialogButton' }, '\n  Filters \n    ', this.withSelection ? [_createElement('svg', {
                                    'className': 'cm_icon cm_filter-icon',
                                    'viewBox': '-5 0 394 394.00003',
                                    'key': '740'
                                }, _createElement('path', { 'd': 'm367.82 0h-351.26c-6.1992-0.011719-11.879 3.4492-14.711 8.9609-2.8711 5.5859-2.3672 12.312 1.3008 17.414l128.69 181.29c0.042968 0.0625 0.089843 0.12109 0.13281 0.18359 4.6758 6.3125 7.207 13.961 7.2188 21.816v147.8c-0.027344 4.375 1.6914 8.582 4.7734 11.688 3.0859 3.1016 7.2812 4.8516 11.656 4.8516 2.2227-0.003906 4.4258-0.44531 6.4805-1.3008l72.312-27.57c6.4766-1.9805 10.777-8.0938 10.777-15.453v-120.02c0.011719-7.8555 2.543-15.504 7.2148-21.816 0.042968-0.0625 0.089844-0.12109 0.13281-0.18359l128.69-181.29c3.668-5.0977 4.1719-11.82 1.3008-17.406-2.8281-5.5156-8.5117-8.9766-14.707-8.9648z' }))] : null, !this.withSelection ? [_createElement('svg', {
                                    'className': 'cm_icon cm_filter-icon',
                                    'viewBox': '0 0 247.46 247.46',
                                    'key': '8150'
                                }, _createElement('path', { 'd': 'm246.74 13.984c-1.238-2.626-3.881-4.301-6.784-4.301h-232.46c-2.903 0-5.545 1.675-6.784 4.301-1.238 2.626-0.85 5.73 0.997 7.97l89.361 108.38v99.94c0 2.595 1.341 5.005 3.545 6.373 1.208 0.749 2.579 1.127 3.955 1.127 1.137 0 2.278-0.259 3.33-0.78l50.208-24.885c2.551-1.264 4.165-3.863 4.169-6.71l0.098-75.062 89.366-108.39c1.848-2.239 2.237-5.344 0.999-7.969zm-103.65 108.89c-1.105 1.34-1.711 3.023-1.713 4.761l-0.096 73.103-35.213 17.453v-90.546c0-1.741-0.605-3.428-1.713-4.771l-80.958-98.191h200.65l-80.958 98.191z' }))] : null);
                        }, {
                            widgetName: 'undefined',
                            items: undefined
                        })]);
                }, {
                    widgetName: 'undefined',
                    items: undefined
                })], [_createElement('div', {
                    'className': 'cm_view',
                    'key': 'viewToggle'
                }, _createElement('div', { 'className': 'product__grid_column_buttons d-sm-none' }, _createElement('button', {
                    'onClick': this.setView('grid-2'),
                    'className': 'gird__column_icon product_col_two' + (this.view === 'grid-2' ? ' active' : '')
                }, _createElement('span', {}, [_createElement('svg', {
                        'fill': 'currentColor',
                        'xmlns': 'http://www.w3.org/2000/svg',
                        'viewBox': '0 0 5.5 12.5',
                        'key': '24780'
                    }, _createElement('g', {
                        'id': 'Layer_2',
                        'data-name': 'Layer 2'
                    }, _createElement('g', {
                        'id': 'Layer_1-2',
                        'data-name': 'Layer 1'
                    }, _createElement('g', {
                        'id': 'shop_page',
                        'data-name': 'shop page'
                    }, _createElement('g', { 'id': 'Group-10' }, _createElement('path', {
                        'id': 'Rectangle',
                        'd': 'M.75 0a.76.76 0 01.75.75v11a.76.76 0 01-.75.75.76.76 0 01-.75-.75v-11A.76.76 0 01.75 0z',
                        'className': 'cls-1'
                    }), _createElement('path', {
                        'id': 'Rectangle-2',
                        'd': 'M4.75 0a.76.76 0 01.75.75v11a.76.76 0 01-.75.75.76.76 0 01-.75-.75v-11A.76.76 0 014.75 0z',
                        'className': 'cls-1',
                        'data-name': 'Rectangle'
                    }))))))])), _createElement('button', {
                    'onClick': this.setView('grid-3'),
                    'className': 'gird__column_icon product_col_three' + (this.view === 'grid-3' ? ' active' : '')
                }, _createElement('span', {}, [_createElement('svg', {
                        'fill': 'currentColor',
                        'xmlns': 'http://www.w3.org/2000/svg',
                        'viewBox': '0 0 9.5 12.5',
                        'key': '33160'
                    }, _createElement('g', {
                        'id': 'Layer_2',
                        'data-name': 'Layer 2'
                    }, _createElement('g', {
                        'id': 'Layer_1-2',
                        'data-name': 'Layer 1'
                    }, _createElement('g', {
                        'id': 'shop_page',
                        'data-name': 'shop page'
                    }, _createElement('g', { 'id': 'Group-16' }, _createElement('path', {
                        'id': 'Rectangle',
                        'd': 'M.75 0a.76.76 0 01.75.75v11a.76.76 0 01-.75.75.76.76 0 01-.75-.75v-11A.76.76 0 01.75 0z',
                        'className': 'cls-1'
                    }), _createElement('path', {
                        'id': 'Rectangle-2',
                        'd': 'M4.75 0a.76.76 0 01.75.75v11a.76.76 0 01-.75.75.76.76 0 01-.75-.75v-11A.76.76 0 014.75 0z',
                        'className': 'cls-1',
                        'data-name': 'Rectangle'
                    }), _createElement('path', {
                        'id': 'Rectangle-3',
                        'd': 'M8.75 0a.76.76 0 01.75.75v11a.76.76 0 01-.75.75.76.76 0 01-.75-.75v-11A.76.76 0 018.75 0z',
                        'className': 'cls-1',
                        'data-name': 'Rectangle'
                    }))))))])), _createElement('button', {
                    'onClick': this.setView('grid-4'),
                    'className': 'gird__column_icon product_col_four' + (this.view === 'grid-4' ? ' active' : '')
                }, _createElement('span', {}, [_createElement('svg', {
                        'fill': 'currentColor',
                        'xmlns': 'http://www.w3.org/2000/svg',
                        'viewBox': '0 0 13.5 12.5',
                        'key': '43180'
                    }, _createElement('g', {
                        'id': 'Layer_2',
                        'data-name': 'Layer 2'
                    }, _createElement('g', {
                        'id': 'Layer_1-2',
                        'data-name': 'Layer 1'
                    }, _createElement('g', {
                        'id': 'shop_page',
                        'data-name': 'shop page'
                    }, _createElement('g', {
                        'id': '_4_col',
                        'data-name': '4_col'
                    }, _createElement('path', {
                        'id': 'Rectangle',
                        'd': 'M.75 0a.76.76 0 01.75.75v11a.76.76 0 01-.75.75.76.76 0 01-.75-.75v-11A.76.76 0 01.75 0z',
                        'className': 'cls-1'
                    }), _createElement('path', {
                        'id': 'Rectangle-2',
                        'd': 'M4.75 0a.76.76 0 01.75.75v11a.76.76 0 01-.75.75.76.76 0 01-.75-.75v-11A.76.76 0 014.75 0z',
                        'className': 'cls-1',
                        'data-name': 'Rectangle'
                    }), _createElement('path', {
                        'id': 'Rectangle-3',
                        'd': 'M8.75 0a.76.76 0 01.75.75v11a.76.76 0 01-.75.75.76.76 0 01-.75-.75v-11A.76.76 0 018.75 0z',
                        'className': 'cls-1',
                        'data-name': 'Rectangle'
                    }), _createElement('path', {
                        'id': 'Rectangle-4',
                        'd': 'M12.75 0a.76.76 0 01.75.75v11a.76.76 0 01-.75.75.76.76 0 01-.75-.75v-11a.76.76 0 01.75-.75z',
                        'className': 'cls-1',
                        'data-name': 'Rectangle'
                    }))))))]))))], _createElement('div', { 'className': 'cm_sort sort-by' }, _createElement('span', { 'className': 'sort-label' }, 'Sort By'), '\n  ', this.sortSelect, '\n'), _createElement('div', { 'className': 'cm_total-hits' }, _createElement('span', {}, this.totalHits, ' results')));
        }, {
            widgetName: 'search-header',
            items: undefined
        })], [_createElement('div', {
            'className': 'cm_FacetPanel',
            'key': '83530'
        }, [this.VehicleSpecs(function () {
                function repeatSelects1(selects, selectsIndex) {
                    return [selects(function () {
                            function repeatEntry1(popularEntries, unpopularEntries, isPopularField, entry, entryIndex) {
                                return _createElement('option', {
                                    'disabled': this.loading,
                                    'value': entry.term,
                                    'key': entry.value
                                }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                            }
                            function repeatEntry2(popularEntries, unpopularEntries, isPopularField, entry, entryIndex) {
                                return _createElement('option', {
                                    'disabled': this.loading,
                                    'value': entry.term,
                                    'key': entry.value
                                }, '\n          ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n        ');
                            }
                            function scopePopularEntriesUnpopularEntriesIsPopularField3() {
                                var popularEntries = this.entries.filter(entry => entry.payload === 'Popular');
                                var unpopularEntries = this.entries.filter(entry => entry.payload !== 'Popular');
                                var isPopularField = popularEntries.length && unpopularEntries.length;
                                return [
                                    !(this.hideNullOption && this.entries.some(e => e.selected)) ? _createElement('option', {
                                        'value': '',
                                        'key': 'null-option'
                                    }, '\n          ', this.title, '\n        ') : null,
                                    this.loading ? _createElement('option', {
                                        'key': 'loading-option',
                                        'disabled': true
                                    }, '...loading...') : null,
                                    isPopularField ? [
                                        _createElement('option', {
                                            'className': 'cm_option_title',
                                            'disabled': true,
                                            'key': '10541'
                                        }, 'Popular ', this.title === 'Make' ? 'Makes' : this.title === 'Model' ? 'Models' : 'Values'),
                                        _map(popularEntries, repeatEntry1.bind(this, popularEntries, unpopularEntries, isPopularField)),
                                        _createElement('option', {
                                            'className': 'cm_option_title',
                                            'disabled': true,
                                            'key': '10544'
                                        }, 'Additional ', this.title === 'Make' ? 'Makes' : this.title === 'Model' ? 'Models' : 'Values')
                                    ] : null,
                                    _map(isPopularField ? unpopularEntries : this.entries, repeatEntry2.bind(this, popularEntries, unpopularEntries, isPopularField))
                                ];
                            }
                            function scopeShowSelectedValues4() {
                                var showSelectedValues = this.selectedEntries.length;
                                return _createElement('option', {
                                    'key': '_current',
                                    'value': '_current'
                                }, showSelectedValues ? [this.selectedEntries.map(e => e.value).join(', ')] : null, !showSelectedValues ? [this.title] : null);
                            }
                            function repeatEntry5(entry, index) {
                                return _createElement('div', {
                                    'className': 'option' + (this.isSearchable && this.activeIndex === index || !this.isSearchable && entry.selected ? ' cm_checked' : ''),
                                    'key': entry.value,
                                    'onClick': () => this.onChange(entry.term)
                                }, this.showCheckboxes ? _createElement('input', {
                                    'type': 'checkbox',
                                    'readOnly': true,
                                    'checked': entry.selected,
                                    'className': 'cm_dropdown_checkbox cm_facet_checkbox',
                                    'key': '3378'
                                }) : null, _createElement('div', { 'className': 'cm_dropdown_value' }, entry.value), this.showHitCount ? _createElement('div', {
                                    'className': 'cm_dropdown_hitCount',
                                    'key': '3580'
                                }, entry.hitCount) : null);
                            }
                            return _createElement('div', { 'className': this.extraClassName }, !this.isSearchable ? _createElement('div', {
                                'className': 'cm_select_toggle' + (this.useNativeDropdown ? ' cm_select_toggle__native' : '') + ' cm_toggle-dropdown',
                                'key': '39'
                            }, _createElement('select', {
                                'className': 'cm_select_inner-select cm_select__pretty',
                                'onChange': this.onChange,
                                'value': this.useNativeDropdown ? this.firstSelectedTerm || '' : '_current',
                                'disabled': this.disabled,
                                'aria-label': this.title
                            }, this.useNativeDropdown ? scopePopularEntriesUnpopularEntriesIsPopularField3.apply(this, []) : null, !this.useNativeDropdown ? scopeShowSelectedValues4.apply(this, []) : null)) : null, this.isSearchable ? [[this.filterInput(function () {
                                        return _createElement('div', { 'className': 'cm_filterInput' });
                                    }, {
                                        widgetName: 'undefined',
                                        items: undefined
                                    })]] : null, !this.disabled && !this.useNativeDropdown ? _createElement.apply(this, [
                                'div',
                                {
                                    'className': 'cm_dropdown cm_select_dropdown cm_hide',
                                    'aria-label': this.title,
                                    'key': '2688'
                                },
                                !this.hideNullOption ? _createElement('div', {
                                    'className': 'option',
                                    'key': 'null-option',
                                    'onClick': () => this.onChange('')
                                }, '\n      ', this.title, '\n    ') : null,
                                this.loading ? _createElement('div', {
                                    'className': 'option',
                                    'key': 'null-option',
                                    'disabled': true
                                }, '...loading...') : null,
                                !this.loading ? _map(this.entries, repeatEntry5.bind(this)) : null
                            ]) : null);
                        }, { count: undefined })];
                }
                return _createElement('div', { 'className': 'cm_VehicleSpecs' }, this.selects.length ? _createElement('div', {
                    'className': 'cm_vehicle-widget cm_vehicle-widget__specs',
                    'key': '29'
                }, _createElement('div', { 'className': 'facetholder' }, _createElement('div', { 'className': 'facettitle' }, 'Vehicle specs'), _createElement('div', { 'className': 'facetbody cm_vehicle-widget_body' }, _createElement.apply(this, [
                    'div',
                    { 'className': 'cm_vehicle-widget_select-container  cmRepeater_selects' },
                    _map(this.selects, repeatSelects1.bind(this))
                ]), _createElement('div', {
                    'className': 'cm_btn cm_button cm_button__secondary cm_filter-chips_button',
                    'title': 'Reset',
                    'onClick': this.discardExtraFields
                }, '\n        Reset\n      ')))) : null);
            }, {
                widgetName: 'undefined',
                items: undefined
            })], [this.FilterChips(function () {
                return _createElement('div', { 'className': 'cm_FilterChips' }, [this.filterChips(function () {
                        function repeatChips1(chips, chipsIndex) {
                            return [chips(function () {
                                    function repeatI1_reviewChip(i, iIndex) {
                                        return _createElement('span', {
                                            'key': `${ this.field }|${ i }`,
                                            'className': 'cm_star cm_star__' + (!(this.value - i) || this.value - i < 0.25 ? 'empty' : this.value - i < 0.75 ? 'half' : 'full')
                                        });
                                    }
                                    function scopeInchMm1_rangeChip() {
                                        var inch = [].includes(this.field) ? '"' : '';
                                        var mm = [
                                            'wheel_bore',
                                            'wheel_offset'
                                        ].includes(this.field) ? 'mm' : '';
                                        return _createElement('div', { 'className': 'facetdiv cmTemplate_rangeChip' }, _createElement('a', {
                                            'className': 'facetentrykey',
                                            'tabIndex': '0',
                                            'role': 'listitem'
                                        }, _createElement('span', { 'className': 'cm_chip-name' }, this.name, ':'), this.finite ? _createElement(_Fragment, { 'key': '365' }, this.selectedRange[0], inch, mm, ' to ', this.selectedRange[1], inch, mm) : null, this.toPosInf ? _createElement(_Fragment, { 'key': '491' }, this.selectedRange[0], inch, mm, ' and more') : null, this.toNegInf ? _createElement(_Fragment, { 'key': '591' }, this.selectedRange[1], inch, mm, ' and less') : null), _createElement('div', { 'className': 'facetentryval' }, [_createElement('svg', {
                                                'className': 'cm_icon cm_icon-times',
                                                'height': '20px',
                                                'role': 'img',
                                                'viewBox': '0 0 22 22',
                                                'key': '7210'
                                            }, _createElement('path', { 'd': 'M1,1L21,21M21,1L1,21' }))]));
                                    }
                                    return this.template === 'chip' ? _createElement('div', { 'className': 'facetdiv cmTemplate_chip' }, _createElement('a', {
                                        'className': 'facetentrykey',
                                        'tabIndex': '0',
                                        'role': 'listitem'
                                    }, _createElement('span', { 'className': 'cm_chip-name' }, this.name === 'filterQuery' ? 'search within' : this.name, ':'), '\n  ', this.value, '\n'), _createElement('div', { 'className': 'facetentryval' }, [_createElement('svg', {
                                            'className': 'cm_icon cm_icon-times',
                                            'height': '20px',
                                            'role': 'img',
                                            'viewBox': '0 0 22 22',
                                            'key': '2570'
                                        }, _createElement('path', { 'd': 'M1,1L21,21M21,1L1,21' }))])) : this.template === 'reviewChip' ? _createElement('div', { 'className': 'facetdiv cmTemplate_reviewChip' }, _createElement.apply(this, [
                                        'a',
                                        {
                                            'className': 'facetentrykey',
                                            'tabIndex': '0',
                                            'role': 'listitem'
                                        },
                                        _createElement('span', { 'className': 'cm_chip-name' }, this.name, ': '),
                                        _map([
                                            0,
                                            1,
                                            2,
                                            3,
                                            4
                                        ], repeatI1_reviewChip.bind(this)),
                                        this.value !== '5' ? _createElement('span', { 'key': '391' }, ' and up') : null
                                    ]), _createElement('div', { 'className': 'facetentryval' }, [_createElement('svg', {
                                            'className': 'cm_icon cm_icon-times',
                                            'height': '20px',
                                            'role': 'img',
                                            'viewBox': '0 0 22 22',
                                            'key': '4900'
                                        }, _createElement('path', { 'd': 'M1,1L21,21M21,1L1,21' }))])) : this.template === 'rangeChip' ? scopeInchMm1_rangeChip.apply(this, []) : _createElement('div', { 'className': 'cmTemplate_Unknow' }, JSON.stringify(this));
                                }, { count: undefined })];
                        }
                        return _createElement('div', { 'className': 'cm_filterChips' }, _createElement('div', { 'className': 'facetholder' }, _createElement('div', { 'className': 'facettitle-container' }, _createElement('div', {
                            'className': 'facettitle',
                            'tabIndex': '0'
                        }, 'Filter:'), _createElement('div', {
                            'className': 'cm_btn cm_button cm_button__secondary cm_filter-chips_button',
                            'data-cm-role': 'start-over'
                        }, '\n        Remove all\n      ')), _createElement('div', { 'className': 'facetbody' }, _createElement.apply(this, [
                            'div',
                            {
                                'role': 'list',
                                'className': 'cmRepeater_chips'
                            },
                            _map(this.chips, repeatChips1.bind(this))
                        ]))));
                    }, {
                        widgetName: 'filter-chips',
                        items: undefined
                    })]);
            }, {
                widgetName: 'undefined',
                items: undefined
            })], [this.FacetBarWheels(function () {
                function repeatSelects1(facet_bar_label, selects, selectsIndex) {
                    return [selects(function () {
                            function repeatEntry1(popularEntries, unpopularEntries, isPopularField, entry, entryIndex) {
                                return _createElement('option', {
                                    'disabled': this.loading,
                                    'value': entry.term,
                                    'key': entry.value
                                }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                            }
                            function repeatEntry2(popularEntries, unpopularEntries, isPopularField, entry, entryIndex) {
                                return _createElement('option', {
                                    'disabled': this.loading,
                                    'value': entry.term,
                                    'key': entry.value
                                }, '\n          ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n        ');
                            }
                            function scopePopularEntriesUnpopularEntriesIsPopularField3() {
                                var popularEntries = this.entries.filter(entry => entry.payload === 'Popular');
                                var unpopularEntries = this.entries.filter(entry => entry.payload !== 'Popular');
                                var isPopularField = popularEntries.length && unpopularEntries.length;
                                return [
                                    !(this.hideNullOption && this.entries.some(e => e.selected)) ? _createElement('option', {
                                        'value': '',
                                        'key': 'null-option'
                                    }, '\n          ', this.title, '\n        ') : null,
                                    this.loading ? _createElement('option', {
                                        'key': 'loading-option',
                                        'disabled': true
                                    }, '...loading...') : null,
                                    isPopularField ? [
                                        _createElement('option', {
                                            'className': 'cm_option_title',
                                            'disabled': true,
                                            'key': '11641'
                                        }, 'Popular ', this.title === 'Make' ? 'Makes' : this.title === 'Model' ? 'Models' : 'Values'),
                                        _map(popularEntries, repeatEntry1.bind(this, popularEntries, unpopularEntries, isPopularField)),
                                        _createElement('option', {
                                            'className': 'cm_option_title',
                                            'disabled': true,
                                            'key': '11644'
                                        }, 'Additional ', this.title === 'Make' ? 'Makes' : this.title === 'Model' ? 'Models' : 'Values')
                                    ] : null,
                                    _map(isPopularField ? unpopularEntries : this.entries, repeatEntry2.bind(this, popularEntries, unpopularEntries, isPopularField))
                                ];
                            }
                            function scopeShowSelectedValues4() {
                                var showSelectedValues = this.selectedEntries.length;
                                return _createElement('option', {
                                    'key': '_current',
                                    'value': '_current'
                                }, showSelectedValues ? [this.selectedEntries.map(e => e.value).join(', ')] : null, !showSelectedValues ? [this.title] : null);
                            }
                            function repeatEntry5(entry, index) {
                                return _createElement('div', {
                                    'className': 'option' + (this.isSearchable && this.activeIndex === index || !this.isSearchable && entry.selected ? ' cm_checked' : ''),
                                    'key': entry.value,
                                    'onClick': () => this.onChange(entry.term)
                                }, this.showCheckboxes ? _createElement('input', {
                                    'type': 'checkbox',
                                    'readOnly': true,
                                    'checked': entry.selected,
                                    'className': 'cm_dropdown_checkbox cm_facet_checkbox',
                                    'key': '3488'
                                }) : null, _createElement('div', { 'className': 'cm_dropdown_value' }, entry.value), this.showHitCount ? _createElement('div', {
                                    'className': 'cm_dropdown_hitCount',
                                    'key': '3690'
                                }, entry.hitCount) : null);
                            }
                            return _createElement('div', { 'className': 'cmTemplate_selectFacet' }, _createElement('div', { 'className': 'cm_vehicle-widget_select' }, [_createElement('div', {
                                    'className': this.extraClassName,
                                    'key': '980'
                                }, !this.isSearchable ? _createElement('div', {
                                    'className': 'cm_select_toggle' + (this.useNativeDropdown ? ' cm_select_toggle__native' : '') + ' cm_toggle-dropdown',
                                    'key': '149'
                                }, _createElement('select', {
                                    'className': 'cm_select_inner-select cm_select__pretty',
                                    'onChange': this.onChange,
                                    'value': this.useNativeDropdown ? this.firstSelectedTerm || '' : '_current',
                                    'disabled': this.disabled,
                                    'aria-label': this.title
                                }, this.useNativeDropdown ? scopePopularEntriesUnpopularEntriesIsPopularField3.apply(this, []) : null, !this.useNativeDropdown ? scopeShowSelectedValues4.apply(this, []) : null)) : null, this.isSearchable ? [[this.filterInput(function () {
                                            return _createElement('div', { 'className': 'cm_filterInput' });
                                        }, {
                                            widgetName: 'undefined',
                                            items: undefined
                                        })]] : null, !this.disabled && !this.useNativeDropdown ? _createElement.apply(this, [
                                    'div',
                                    {
                                        'className': 'cm_dropdown cm_select_dropdown cm_hide',
                                        'aria-label': this.title,
                                        'key': '2798'
                                    },
                                    !this.hideNullOption ? _createElement('div', {
                                        'className': 'option',
                                        'key': 'null-option',
                                        'onClick': () => this.onChange('')
                                    }, '\n      ', this.title, '\n    ') : null,
                                    this.loading ? _createElement('div', {
                                        'className': 'option',
                                        'key': 'null-option',
                                        'disabled': true
                                    }, '...loading...') : null,
                                    !this.loading ? _map(this.entries, repeatEntry5.bind(this)) : null
                                ]) : null)]));
                        }, { count: undefined })];
                }
                function scopeFacet_bar_label2() {
                    var facet_bar_label = 'Shop Wheels';
                    return _createElement('div', { 'className': 'cm_FacetBarWheels' }, _createElement('div', { 'className': 'cm_FacetBarWheelsTires cm_facets' }, _createElement('div', { 'className': 'cm_vehicle-widget cm_vehicle-widget__facet-bar facetholder' }, _createElement('div', { 'className': 'cm_vehicle-widget_dropdowns' }, _createElement('div', { 'className': 'cm_vehicle-widget_title facettitle' }, '\n        ', facet_bar_label, '\n      '), _createElement('div', { 'className': 'cm_vehicle-widget_body facetbody' }, _createElement.apply(this, [
                        'div',
                        { 'className': 'cm_vehicle-widget_select-container cmRepeater_facets cmRepeater_selects' },
                        _map(this.selects, repeatSelects1.bind(this, facet_bar_label))
                    ]), _createElement('div', { 'className': 'cm_vehicle-widget_button-container' }, _createElement('div', {
                        'className': 'cm_vehicle-widget_button cm_vehicle-widget_button__clear cm_button cm_button__secondary',
                        'onClick': this.discardFields,
                        'disabled': !this.hasSelection,
                        'role': 'button',
                        'tabIndex': this.hasSelection ? '0' : '-1'
                    }, [_createElement('svg', {
                            'xmlns': 'http://www.w3.org/2000/svg',
                            'className': 'cm_icon cm_icon-reset',
                            'height': '16px',
                            'viewBox': '0 0 16 16',
                            'key': '68790'
                        }, _createElement('path', { 'd': 'M2.083,9H0.062H0v5l1.481-1.361C2.932,14.673,5.311,16,8,16c4.08,0,7.446-3.054,7.938-7h-2.021 c-0.476,2.838-2.944,5-5.917,5c-2.106,0-3.96-1.086-5.03-2.729L5.441,9H2.083z' }), _createElement('path', { 'd': 'M8,0C3.92,0,0.554,3.054,0.062,7h2.021C2.559,4.162,5.027,2,8,2c2.169,0,4.07,1.151,5.124,2.876 L11,7h2h0.917h2.021H16V2l-1.432,1.432C13.123,1.357,10.72,0,8,0z' }))])))))));
                }
                return scopeFacet_bar_label2.apply(this, []);
            }, {
                widgetName: 'undefined',
                items: undefined
            })], [this.FacetBarTires(function () {
                function repeatSelects1(facet_bar_label, selects, selectsIndex) {
                    return [selects(function () {
                            function repeatEntry1(popularEntries, unpopularEntries, isPopularField, entry, entryIndex) {
                                return _createElement('option', {
                                    'disabled': this.loading,
                                    'value': entry.term,
                                    'key': entry.value
                                }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                            }
                            function repeatEntry2(popularEntries, unpopularEntries, isPopularField, entry, entryIndex) {
                                return _createElement('option', {
                                    'disabled': this.loading,
                                    'value': entry.term,
                                    'key': entry.value
                                }, '\n          ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n        ');
                            }
                            function scopePopularEntriesUnpopularEntriesIsPopularField3() {
                                var popularEntries = this.entries.filter(entry => entry.payload === 'Popular');
                                var unpopularEntries = this.entries.filter(entry => entry.payload !== 'Popular');
                                var isPopularField = popularEntries.length && unpopularEntries.length;
                                return [
                                    !(this.hideNullOption && this.entries.some(e => e.selected)) ? _createElement('option', {
                                        'value': '',
                                        'key': 'null-option'
                                    }, '\n          ', this.title, '\n        ') : null,
                                    this.loading ? _createElement('option', {
                                        'key': 'loading-option',
                                        'disabled': true
                                    }, '...loading...') : null,
                                    isPopularField ? [
                                        _createElement('option', {
                                            'className': 'cm_option_title',
                                            'disabled': true,
                                            'key': '11641'
                                        }, 'Popular ', this.title === 'Make' ? 'Makes' : this.title === 'Model' ? 'Models' : 'Values'),
                                        _map(popularEntries, repeatEntry1.bind(this, popularEntries, unpopularEntries, isPopularField)),
                                        _createElement('option', {
                                            'className': 'cm_option_title',
                                            'disabled': true,
                                            'key': '11644'
                                        }, 'Additional ', this.title === 'Make' ? 'Makes' : this.title === 'Model' ? 'Models' : 'Values')
                                    ] : null,
                                    _map(isPopularField ? unpopularEntries : this.entries, repeatEntry2.bind(this, popularEntries, unpopularEntries, isPopularField))
                                ];
                            }
                            function scopeShowSelectedValues4() {
                                var showSelectedValues = this.selectedEntries.length;
                                return _createElement('option', {
                                    'key': '_current',
                                    'value': '_current'
                                }, showSelectedValues ? [this.selectedEntries.map(e => e.value).join(', ')] : null, !showSelectedValues ? [this.title] : null);
                            }
                            function repeatEntry5(entry, index) {
                                return _createElement('div', {
                                    'className': 'option' + (this.isSearchable && this.activeIndex === index || !this.isSearchable && entry.selected ? ' cm_checked' : ''),
                                    'key': entry.value,
                                    'onClick': () => this.onChange(entry.term)
                                }, this.showCheckboxes ? _createElement('input', {
                                    'type': 'checkbox',
                                    'readOnly': true,
                                    'checked': entry.selected,
                                    'className': 'cm_dropdown_checkbox cm_facet_checkbox',
                                    'key': '3488'
                                }) : null, _createElement('div', { 'className': 'cm_dropdown_value' }, entry.value), this.showHitCount ? _createElement('div', {
                                    'className': 'cm_dropdown_hitCount',
                                    'key': '3690'
                                }, entry.hitCount) : null);
                            }
                            return _createElement('div', { 'className': 'cmTemplate_selectFacet' }, _createElement('div', { 'className': 'cm_vehicle-widget_select' }, [_createElement('div', {
                                    'className': this.extraClassName,
                                    'key': '980'
                                }, !this.isSearchable ? _createElement('div', {
                                    'className': 'cm_select_toggle' + (this.useNativeDropdown ? ' cm_select_toggle__native' : '') + ' cm_toggle-dropdown',
                                    'key': '149'
                                }, _createElement('select', {
                                    'className': 'cm_select_inner-select cm_select__pretty',
                                    'onChange': this.onChange,
                                    'value': this.useNativeDropdown ? this.firstSelectedTerm || '' : '_current',
                                    'disabled': this.disabled,
                                    'aria-label': this.title
                                }, this.useNativeDropdown ? scopePopularEntriesUnpopularEntriesIsPopularField3.apply(this, []) : null, !this.useNativeDropdown ? scopeShowSelectedValues4.apply(this, []) : null)) : null, this.isSearchable ? [[this.filterInput(function () {
                                            return _createElement('div', { 'className': 'cm_filterInput' });
                                        }, {
                                            widgetName: 'undefined',
                                            items: undefined
                                        })]] : null, !this.disabled && !this.useNativeDropdown ? _createElement.apply(this, [
                                    'div',
                                    {
                                        'className': 'cm_dropdown cm_select_dropdown cm_hide',
                                        'aria-label': this.title,
                                        'key': '2798'
                                    },
                                    !this.hideNullOption ? _createElement('div', {
                                        'className': 'option',
                                        'key': 'null-option',
                                        'onClick': () => this.onChange('')
                                    }, '\n      ', this.title, '\n    ') : null,
                                    this.loading ? _createElement('div', {
                                        'className': 'option',
                                        'key': 'null-option',
                                        'disabled': true
                                    }, '...loading...') : null,
                                    !this.loading ? _map(this.entries, repeatEntry5.bind(this)) : null
                                ]) : null)]));
                        }, { count: undefined })];
                }
                function scopeFacet_bar_label2() {
                    var facet_bar_label = 'Shop Tires';
                    return _createElement('div', { 'className': 'cm_FacetBarTires' }, _createElement('div', { 'className': 'cm_FacetBarWheelsTires cm_facets' }, _createElement('div', { 'className': 'cm_vehicle-widget cm_vehicle-widget__facet-bar facetholder' }, _createElement('div', { 'className': 'cm_vehicle-widget_dropdowns' }, _createElement('div', { 'className': 'cm_vehicle-widget_title facettitle' }, '\n        ', facet_bar_label, '\n      '), _createElement('div', { 'className': 'cm_vehicle-widget_body facetbody' }, _createElement.apply(this, [
                        'div',
                        { 'className': 'cm_vehicle-widget_select-container cmRepeater_facets cmRepeater_selects' },
                        _map(this.selects, repeatSelects1.bind(this, facet_bar_label))
                    ]), _createElement('div', { 'className': 'cm_vehicle-widget_button-container' }, _createElement('div', {
                        'className': 'cm_vehicle-widget_button cm_vehicle-widget_button__clear cm_button cm_button__secondary',
                        'onClick': this.discardFields,
                        'disabled': !this.hasSelection,
                        'role': 'button',
                        'tabIndex': this.hasSelection ? '0' : '-1'
                    }, [_createElement('svg', {
                            'xmlns': 'http://www.w3.org/2000/svg',
                            'className': 'cm_icon cm_icon-reset',
                            'height': '16px',
                            'viewBox': '0 0 16 16',
                            'key': '68770'
                        }, _createElement('path', { 'd': 'M2.083,9H0.062H0v5l1.481-1.361C2.932,14.673,5.311,16,8,16c4.08,0,7.446-3.054,7.938-7h-2.021 c-0.476,2.838-2.944,5-5.917,5c-2.106,0-3.96-1.086-5.03-2.729L5.441,9H2.083z' }), _createElement('path', { 'd': 'M8,0C3.92,0,0.554,3.054,0.062,7h2.021C2.559,4.162,5.027,2,8,2c2.169,0,4.07,1.151,5.124,2.876 L11,7h2h0.917h2.021H16V2l-1.432,1.432C13.123,1.357,10.72,0,8,0z' }))])))))));
                }
                return scopeFacet_bar_label2.apply(this, []);
            }, {
                widgetName: 'undefined',
                items: undefined
            })], [this.Facets(function () {
                return _createElement('div', { 'className': 'cm_Facets' }, [this.facets(function () {
                        function repeatFacets1(facets, facetsIndex) {
                            return [facets(function () {
                                    function repeatShowAlwaysValues1(ShowAlwaysValues, ShowAlwaysValuesIndex) {
                                        return [ShowAlwaysValues(function () {
                                                return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass }, _createElement('input', {
                                                    'type': 'checkbox',
                                                    'className': 'cm_facet_checkbox',
                                                    'readOnly': true,
                                                    'checked': this.isSelected
                                                }), _createElement('a', {
                                                    'className': 'facetentrykey',
                                                    'tabIndex': '0',
                                                    'role': 'listitem'
                                                }, this.value), _createElement('div', { 'className': 'facetentryval' }, this.hitCount));
                                            }, { count: undefined })];
                                    }
                                    function repeatValues2(Values, ValuesIndex) {
                                        return [Values(function () {
                                                return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass }, _createElement('input', {
                                                    'type': 'checkbox',
                                                    'className': 'cm_facet_checkbox',
                                                    'readOnly': true,
                                                    'checked': this.isSelected
                                                }), _createElement('a', {
                                                    'className': 'facetentrykey',
                                                    'tabIndex': '0',
                                                    'role': 'listitem'
                                                }, this.value), _createElement('div', { 'className': 'facetentryval' }, this.hitCount));
                                            }, { count: undefined })];
                                    }
                                    function repeatValues3(Values, ValuesIndex) {
                                        return [Values(function () {
                                                return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass }, _createElement('input', {
                                                    'type': 'checkbox',
                                                    'className': 'cm_facet_checkbox',
                                                    'readOnly': true,
                                                    'checked': this.isSelected
                                                }), !this.isCustomRange ? [
                                                    _createElement('a', {
                                                        'className': 'facetentrykey',
                                                        'tabIndex': '0',
                                                        'role': 'listitem',
                                                        'key': '1541'
                                                    }, _createElement('span', {}, this.value)),
                                                    _createElement('div', {
                                                        'className': 'facetentryval',
                                                        'key': '1543'
                                                    }, this.hitCount)
                                                ] : null, this.isCustomRange ? [_createElement('a', {
                                                        'className': 'facetentrykey',
                                                        'tabIndex': '0',
                                                        'role': 'listitem',
                                                        'key': '3901'
                                                    }, this.finite ? _createElement('span', { 'key': '503' }, this.range[0], ' - ', this.range[1]) : null, this.toPosInf ? _createElement('span', { 'key': '580' }, this.range[0], ' and more') : null, this.toNegInf ? _createElement('span', { 'key': '650' }, this.range[1], ' and less') : null)] : null);
                                            }, { count: undefined })];
                                    }
                                    function scopeInchMm4() {
                                        var inch = [].includes(this.field) ? '"' : '';
                                        var mm = [
                                            'wheel_bore',
                                            'wheel_offset'
                                        ].includes(this.field) ? 'mm' : '';
                                        return _createElement('div', {
                                            'className': 'cmTemplate_sliderFacet',
                                            'key': '12775'
                                        }, _createElement('div', { 'className': 'facetslider' }, _createElement('div', { 'className': 'facetsliderinfo' }, '\n    Selected: ', this.selectedRange[0], inch, mm, ' to ', this.selectedRange[1], inch, mm, '\n  '), '\n  ', this.slider, '\n  ', _createElement('div', { 'className': 'cm_slider-bounds' }, _createElement('div', { 'className': 'min' }, this.min, inch, mm), _createElement('div', { 'className': 'max' }, this.max, inch, mm), _createElement('div', { 'className': 'clear' }))), [this.Inputs(function () {
                                                return _createElement('div', { 'className': 'input cm_Inputs' }, this.inputMin ? [[this.inputMin(function () {
                                                            return _createElement('div', {
                                                                'placeholder': 'Min',
                                                                'className': 'cm_inputMin'
                                                            });
                                                        }, {
                                                            widgetName: 'undefined',
                                                            items: undefined
                                                        })]] : null, this.inputMin && this.inputMax ? _createElement('span', {
                                                    'className': 'separator',
                                                    'key': '334'
                                                }, '\u2014') : null, this.inputMax ? [[this.inputMax(function () {
                                                            return _createElement('div', {
                                                                'placeholder': 'Max',
                                                                'className': 'cm_inputMax'
                                                            });
                                                        }, {
                                                            widgetName: 'undefined',
                                                            items: undefined
                                                        })]] : null, _createElement('button', {
                                                    'type': 'button',
                                                    'className': 'cm_btn cm_button cm_button__secondary',
                                                    'onClick': this.setCustomRange
                                                }, '\n    GO\n  '));
                                            }, {
                                                widgetName: 'undefined',
                                                items: undefined
                                            })]);
                                    }
                                    function repeatShowAlwaysValues5(ShowAlwaysValues, ShowAlwaysValuesIndex) {
                                        return [ShowAlwaysValues(function () {
                                                function scopeStarFill1(i, iIndex) {
                                                    var starFill = this.value - i;
                                                    return _createElement('span', {
                                                        'key': i,
                                                        'className': 'cm_star cm_star__' + (starFill < 0.25 ? 'empty' : starFill < 0.75 ? 'half' : 'full')
                                                    });
                                                }
                                                function repeatI2(i, iIndex, starFill) {
                                                    return scopeStarFill1.apply(this, [
                                                        i,
                                                        iIndex
                                                    ]);
                                                }
                                                return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass }, _createElement('input', {
                                                    'type': 'checkbox',
                                                    'className': 'cm_facet_checkbox',
                                                    'readOnly': true,
                                                    'checked': this.isSelected
                                                }), _createElement('a', {
                                                    'className': 'facetentrykey',
                                                    'tabIndex': '0',
                                                    'role': 'listitem'
                                                }, _createElement.apply(this, [
                                                    'span',
                                                    { 'className': 'cm_review-stars' },
                                                    _map([
                                                        0,
                                                        1,
                                                        2,
                                                        3,
                                                        4
                                                    ], repeatI2.bind(this))
                                                ]), this.value !== '5' ? _createElement('span', {
                                                    'className': 'cm_and-up',
                                                    'key': '499'
                                                }, ' and up') : null), _createElement('div', { 'className': 'facetentryval' }, this.hitCount));
                                            }, { count: undefined })];
                                    }
                                    function repeatValues6(Values, ValuesIndex) {
                                        return [Values(function () {
                                                function scopeStarFill1(i, iIndex) {
                                                    var starFill = this.value - i;
                                                    return _createElement('span', {
                                                        'key': i,
                                                        'className': 'cm_star cm_star__' + (starFill < 0.25 ? 'empty' : starFill < 0.75 ? 'half' : 'full')
                                                    });
                                                }
                                                function repeatI2(i, iIndex, starFill) {
                                                    return scopeStarFill1.apply(this, [
                                                        i,
                                                        iIndex
                                                    ]);
                                                }
                                                return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass }, _createElement('input', {
                                                    'type': 'checkbox',
                                                    'className': 'cm_facet_checkbox',
                                                    'readOnly': true,
                                                    'checked': this.isSelected
                                                }), _createElement('a', {
                                                    'className': 'facetentrykey',
                                                    'tabIndex': '0',
                                                    'role': 'listitem'
                                                }, _createElement.apply(this, [
                                                    'span',
                                                    { 'className': 'cm_review-stars' },
                                                    _map([
                                                        0,
                                                        1,
                                                        2,
                                                        3,
                                                        4
                                                    ], repeatI2.bind(this))
                                                ]), this.value !== '5' ? _createElement('span', {
                                                    'className': 'cm_and-up',
                                                    'key': '499'
                                                }, ' and up') : null), _createElement('div', { 'className': 'facetentryval' }, this.hitCount));
                                            }, { count: undefined })];
                                    }
                                    return _createElement('div', { 'className': 'facetholder cm_field-' + this.field }, this.template !== 'toggleFacet' ? [
                                        _createElement('div', {
                                            'className': 'facettitle',
                                            'data-cm-role': 'toggle-facet',
                                            'tabIndex': '0',
                                            'key': '561'
                                        }, _createElement('span', {}, this.name), this.isCollapsed ? [_createElement('svg', {
                                                'className': 'cm_icon cm_icon-angle down',
                                                'height': '20px',
                                                'role': 'img',
                                                'viewBox': '39 30 565 565',
                                                'aria-hidden': 'true',
                                                'key': '2360'
                                            }, _createElement('path', { 'd': 'M600,189q0-7-6-12l-28-28q-5-6-12-6t-13,6l-220,219-219-219q-5-6-13-6t-12,6l-28,28q-6,5-6,12t6,13l260,260q5,6,12,6t13-6l260-260q6-5,6-13z' }))] : null, !this.isCollapsed ? [_createElement('svg', {
                                                'className': 'cm_icon cm_icon-angle up',
                                                'height': '20px',
                                                'role': 'img',
                                                'viewBox': '39 30 565 565',
                                                'aria-hidden': 'true',
                                                'key': '5550'
                                            }, _createElement('path', { 'd': 'M600,189q0-7-6-12l-28-28q-5-6-12-6t-13,6l-220,219-219-219q-5-6-13-6t-12,6l-28,28q-6,5-6,12t6,13l260,260q5,6,12,6t13-6l260-260q6-5,6-13z' }))] : null),
                                        _createElement('div', {
                                            'className': 'facetbody' + (this.template === 'simpleFacet' && this.needShowMore && !this.moreValuesToShow ? ' expanded' : ''),
                                            'key': '563'
                                        }, this.template === 'simpleFacet' ? _createElement('div', {
                                            'role': 'list',
                                            'className': 'cmTemplate_simpleFacet',
                                            'key': '1067'
                                        }, this.showFilterInput ? _createElement('div', {
                                            'className': 'filter-input',
                                            'key': '1165'
                                        }, [this.filterInput(function () {
                                                return _createElement('div', {
                                                    'className': 'input  cm_filterInput',
                                                    'placeholder': 'Enter'
                                                });
                                            }, {
                                                widgetName: 'undefined',
                                                items: undefined
                                            })], this.inputNotEmpty ? _createElement('span', {
                                            'className': 'filter-input_clear-container',
                                            'onClick': this.clearInput,
                                            'key': '1485'
                                        }, _createElement('span', { 'className': 'filter-input_clear' }, '\u2715')) : null) : null, !this.ShowAlwaysValues.length && !this.Values.length ? [_createElement('div', {
                                                'className': 'facetdiv',
                                                'key': '16541'
                                            }, _createElement('span', { 'className': 'facetentrykey' }, 'No entries found'))] : null, _createElement('div', { 'className': 'facetvalues' + (this.showFilterInput && !this.moreValuesToShow ? ' overflowed' : '') }, _createElement.apply(this, [
                                            'div',
                                            { 'className': 'cmRepeater_ShowAlwaysValues' },
                                            _map(this.ShowAlwaysValues, repeatShowAlwaysValues1.bind(this))
                                        ]), _createElement.apply(this, [
                                            'div',
                                            { 'className': 'cmRepeater_Values' },
                                            _map(this.Values, repeatValues2.bind(this))
                                        ]), this.needShowMore ? _createElement('div', {
                                            'className': 'facetdiv cm_show-all-container',
                                            'key': '3485'
                                        }, _createElement('a', {
                                            'className': 'cm_show-all',
                                            'data-cm-role': 'toggle-show-more',
                                            'tabIndex': '0'
                                        }, this.moreValuesToShow ? ['Show more'] : null, !this.moreValuesToShow ? ['Show less'] : null)) : null)) : null, this.template === 'selectFacet' ? _createElement('div', {
                                            'className': 'cmTemplate_selectFacet',
                                            'key': '3813'
                                        }, [this.select(function () {
                                                function repeatEntry1(popularEntries, unpopularEntries, isPopularField, entry, entryIndex) {
                                                    return _createElement('option', {
                                                        'disabled': this.loading,
                                                        'value': entry.term,
                                                        'key': entry.value
                                                    }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                                                }
                                                function repeatEntry2(popularEntries, unpopularEntries, isPopularField, entry, entryIndex) {
                                                    return _createElement('option', {
                                                        'disabled': this.loading,
                                                        'value': entry.term,
                                                        'key': entry.value
                                                    }, '\n          ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n        ');
                                                }
                                                function scopePopularEntriesUnpopularEntriesIsPopularField3() {
                                                    var popularEntries = this.entries.filter(entry => entry.payload === 'Popular');
                                                    var unpopularEntries = this.entries.filter(entry => entry.payload !== 'Popular');
                                                    var isPopularField = popularEntries.length && unpopularEntries.length;
                                                    return [
                                                        !(this.hideNullOption && this.entries.some(e => e.selected)) ? _createElement('option', {
                                                            'value': '',
                                                            'key': 'null-option'
                                                        }, '\n          ', this.title, '\n        ') : null,
                                                        this.loading ? _createElement('option', {
                                                            'key': 'loading-option',
                                                            'disabled': true
                                                        }, '...loading...') : null,
                                                        isPopularField ? [
                                                            _createElement('option', {
                                                                'className': 'cm_option_title',
                                                                'disabled': true,
                                                                'key': '10771'
                                                            }, 'Popular ', this.title === 'Make' ? 'Makes' : this.title === 'Model' ? 'Models' : 'Values'),
                                                            _map(popularEntries, repeatEntry1.bind(this, popularEntries, unpopularEntries, isPopularField)),
                                                            _createElement('option', {
                                                                'className': 'cm_option_title',
                                                                'disabled': true,
                                                                'key': '10774'
                                                            }, 'Additional ', this.title === 'Make' ? 'Makes' : this.title === 'Model' ? 'Models' : 'Values')
                                                        ] : null,
                                                        _map(isPopularField ? unpopularEntries : this.entries, repeatEntry2.bind(this, popularEntries, unpopularEntries, isPopularField))
                                                    ];
                                                }
                                                function scopeShowSelectedValues4() {
                                                    var showSelectedValues = this.selectedEntries.length;
                                                    return _createElement('option', {
                                                        'key': '_current',
                                                        'value': '_current'
                                                    }, showSelectedValues ? [this.selectedEntries.map(e => e.value).join(', ')] : null, !showSelectedValues ? [this.title] : null);
                                                }
                                                function repeatEntry5(entry, index) {
                                                    return _createElement('div', {
                                                        'className': 'option' + (this.isSearchable && this.activeIndex === index || !this.isSearchable && entry.selected ? ' cm_checked' : ''),
                                                        'key': entry.value,
                                                        'onClick': () => this.onChange(entry.term)
                                                    }, this.showCheckboxes ? _createElement('input', {
                                                        'type': 'checkbox',
                                                        'readOnly': true,
                                                        'checked': entry.selected,
                                                        'className': 'cm_dropdown_checkbox cm_facet_checkbox',
                                                        'key': '3401'
                                                    }) : null, _createElement('div', { 'className': 'cm_dropdown_value' }, entry.value), this.showHitCount ? _createElement('div', {
                                                        'className': 'cm_dropdown_hitCount',
                                                        'key': '3603'
                                                    }, entry.hitCount) : null);
                                                }
                                                return _createElement('div', { 'className': 'cm_select' }, _createElement('div', { 'className': this.extraClassName }, !this.isSearchable ? _createElement('div', {
                                                    'className': 'cm_select_toggle' + (this.useNativeDropdown ? ' cm_select_toggle__native' : '') + ' cm_toggle-dropdown',
                                                    'key': '62'
                                                }, _createElement('select', {
                                                    'className': 'cm_select_inner-select cm_select__pretty',
                                                    'onChange': this.onChange,
                                                    'value': this.useNativeDropdown ? this.firstSelectedTerm || '' : '_current',
                                                    'disabled': this.disabled,
                                                    'aria-label': this.title
                                                }, this.useNativeDropdown ? scopePopularEntriesUnpopularEntriesIsPopularField3.apply(this, []) : null, !this.useNativeDropdown ? scopeShowSelectedValues4.apply(this, []) : null)) : null, this.isSearchable ? [[this.filterInput(function () {
                                                            return _createElement('div', { 'className': 'cm_filterInput' });
                                                        }, {
                                                            widgetName: 'undefined',
                                                            items: undefined
                                                        })]] : null, !this.disabled && !this.useNativeDropdown ? _createElement.apply(this, [
                                                    'div',
                                                    {
                                                        'className': 'cm_dropdown cm_select_dropdown cm_hide',
                                                        'aria-label': this.title,
                                                        'key': '2711'
                                                    },
                                                    !this.hideNullOption ? _createElement('div', {
                                                        'className': 'option',
                                                        'key': 'null-option',
                                                        'onClick': () => this.onChange('')
                                                    }, '\n      ', this.title, '\n    ') : null,
                                                    this.loading ? _createElement('div', {
                                                        'className': 'option',
                                                        'key': 'null-option',
                                                        'disabled': true
                                                    }, '...loading...') : null,
                                                    !this.loading ? _map(this.entries, repeatEntry5.bind(this)) : null
                                                ]) : null));
                                            }, {
                                                widgetName: 'undefined',
                                                items: undefined
                                            })]) : null, this.template === 'priceFacet' ? _createElement('div', {
                                            'role': 'list',
                                            'className': 'cmTemplate_priceFacet',
                                            'key': '9820'
                                        }, !this.ranges.length ? [
                                            _createElement('div', { 'key': '99161' }, this.selectedRawRange[0], ' to ', this.selectedRawRange[1]),
                                            '\n  ',
                                            this.slider,
                                            '\n  ',
                                            _createElement('div', {
                                                'className': 'cm_flex',
                                                'key': '99163'
                                            }, _createElement('div', { 'className': 'cm_flex-grow' }, 'Min: ', this.rawMin), _createElement('div', {}, 'Max: ', this.rawMax))
                                        ] : null, this.ranges.length ? [_createElement.apply(this, [
                                                'div',
                                                {
                                                    'className': 'cmRepeater_Values',
                                                    'key': '101801'
                                                },
                                                _map(this.Values, repeatValues3.bind(this))
                                            ])] : null, [this.Inputs(function () {
                                                return _createElement('div', { 'className': 'input cm_Inputs' }, [this.inputMin(function () {
                                                        return _createElement('div', {
                                                            'placeholder': 'Min price',
                                                            'className': 'cm_inputMin'
                                                        });
                                                    }, {
                                                        widgetName: 'undefined',
                                                        items: undefined
                                                    })], _createElement('span', { 'className': 'separator' }, '\u2014'), [this.inputMax(function () {
                                                        return _createElement('div', {
                                                            'placeholder': 'Max price',
                                                            'className': 'cm_inputMax'
                                                        });
                                                    }, {
                                                        widgetName: 'undefined',
                                                        items: undefined
                                                    })], _createElement('button', {
                                                    'type': 'button',
                                                    'className': 'cm_btn cm_button cm_button__secondary',
                                                    'onClick': this.setCustomRange
                                                }, '\n    GO\n  '));
                                            }, {
                                                widgetName: 'undefined',
                                                items: undefined
                                            })]) : null, this.template === 'sliderFacet' ? scopeInchMm4.apply(this, []) : null, this.template === 'reviewFacet' ? _createElement('div', {
                                            'role': 'list',
                                            'className': 'cmTemplate_reviewFacet',
                                            'key': '14544'
                                        }, _createElement.apply(this, [
                                            'div',
                                            { 'className': 'cmRepeater_ShowAlwaysValues' },
                                            _map(this.ShowAlwaysValues, repeatShowAlwaysValues5.bind(this))
                                        ]), _createElement.apply(this, [
                                            'div',
                                            { 'className': 'cmRepeater_Values' },
                                            _map(this.Values, repeatValues6.bind(this))
                                        ])) : null)
                                    ] : null, this.template === 'toggleFacet' ? _createElement('div', {
                                        'className': 'cmTemplate_toggleFacet',
                                        'key': '17704'
                                    }, _createElement('div', { 'className': 'facettitle cm_facet-toggle' }, _createElement('span', {}, this.name), _createElement('label', { 'className': 'cm_facet-toggle_switch' }, _createElement('input', {
                                        'className': 'cm_facet-toggle_input',
                                        'type': 'checkbox',
                                        'checked': this.isToggled,
                                        'onClick': this.toggleFacet
                                    }), _createElement('span', { 'className': 'cm_facet-toggle_slider' })))) : null);
                                }, { count: undefined })];
                        }
                        return _createElement('div', { 'className': 'cm_facets' }, _createElement.apply(this, [
                            'div',
                            { 'className': 'cmRepeater_facets' },
                            _map(this.facets, repeatFacets1.bind(this))
                        ]));
                    }, {
                        widgetName: 'undefined',
                        items: undefined
                    })]);
            }, {
                widgetName: 'undefined',
                items: undefined
            })])], _createElement('div', { 'className': 'cm_main-content' }, _createElement('div', { 'id': 'itemsBlock' }, [this.message(function () {
            return _createElement('div', { 'className': 'cm_message' }, this.hasMessage('TrialExpired') ? [_createElement('div', { 'key': '240' }, _createElement('h2', { 'className': 'cm_message-header' }, 'YMM Search trial period has expired.'), '\n  Product information, including pricing and fitments, is outdated. This store runs on a limited trial version\n  of Convermax Year Make Model fitment search. Please contact\n  ', _createElement('a', {
                    'href': 'mailto:support@convermax.com',
                    'className': 'cm_message-link'
                }, 'support@convermax.com'), ' to complete setup\n  or cancel the integration.\n')] : null, this.hasMessage('AggressiveWheels') ? [_createElement('div', { 'key': '4980' }, '\n  Warning: Aggressive wheel options are displayed, but we cannot guarantee they will fit your selected\n  vehicle.\n')] : null, this.hasMessage('IncompatibleParts') && !this.notFilteredByVehicle ? [_createElement('div', {
                    'className': 'cm_message__incompatible',
                    'key': '7050'
                }, _createElement('div', {}, '\n    The products shown below will not fit a ', _createElement('span', {}, this.vehicleString), '.', _createElement('br', {}), '\n    The products results are based on your keyword search only.\n  '))] : null, this.isNoVehicleSpecificResults && this.isVehicleSelected && !this.notFilteredByVehicle && !this.hasMessage('NonVehicleProducts') ? [_createElement('div', {
                    'className': 'cm_message__universal',
                    'key': '10350'
                }, '\n  The system was unable to find any vehicle specific products that fit a\n  ', _createElement('span', {}, '\'', this.vehicleString, '\'.'), _createElement('br', {}), '\n  Click ', _createElement('a', {
                    'tabIndex': '0',
                    'className': 'cm_message-link',
                    'href': this.searchByVehicleUrl
                }, 'here'), ' to clear your vehicle\n  selection and browse all products in the category.\n')] : null, this.template === 'badRequest' ? _createElement('div', {
                'className': 'cmTemplate_badRequest',
                'key': '1568'
            }, 'Your request failed to process (', _createElement('b', {}, 'status:'), ' ', this.error.status, ', ', _createElement('b', {}, 'id:'), ' ', this.error.userId, ',\n', _createElement('b', {}, 'timestamp:'), ' ', this.error.timestamp, ', ', _createElement('b', {}, 'instance:'), ' ', this.error.instance, ').\n', _createElement('br', {}), '\nPlease ', _createElement('a', {
                'tabIndex': '0',
                'className': 'cm_message-link',
                'onClick': this.startOver
            }, 'clear your search'), ' and try\nsearching for something else.\n') : null, this.template === 'corrected' ? _createElement('div', {
                'className': 'cmTemplate_corrected',
                'key': '1982'
            }, 'Your search for ', _createElement('span', { 'className': 'query' }, this.originalQuery), '\ndid not match any products. The system searched for\n', _createElement('span', { 'className': 'query' }, this.query), ' instead.\n') : null, this.template === 'networkError' ? _createElement('div', {
                'className': 'cmTemplate_networkError',
                'key': '2236'
            }, 'Network error\n', this.error ? [
                '(id: ',
                this.error.userId,
                ', timestamp: ',
                this.error.timestamp,
                ', instance: ',
                this.error.instance,
                ')'
            ] : null, '.\n', _createElement('br', {}), '\nThe search server could not be reached. Please try again later.\n') : null, this.template === 'nothing' ? _createElement('div', {
                'className': 'cmTemplate_nothing',
                'key': '2554'
            }, _createElement('div', {}, this.pageType !== 'search' && (!this.isFacetsSelected || this.notFilteredByVehicle) ? [
                '\n    This ',
                this.pageType,
                ' doesn\'t contain any\n    ',
                !!this.vehicleString && !this.notFilteredByVehicle ? [
                    '\n      products that fit a ',
                    _createElement('span', { 'key': '28171' }, '\'', this.vehicleString, '\'.')
                ] : null,
                !this.vehicleString || this.notFilteredByVehicle ? [' products.'] : null
            ] : null, this.pageType === 'search' ? [_createElement('div', { 'key': '30991' }, 'No results were found for your search.')] : null, this.query || !!this.vehicleString && !this.notFilteredByVehicle ? [
                '\n    Click ',
                _createElement('a', {
                    'tabIndex': '0',
                    'className': 'cm_message-link',
                    'onClick': this.startOver,
                    'key': '32301'
                }, 'here'),
                ' to clear\n    ',
                !this.vehicleString ? [' selection. '] : null,
                this.vehicleString && (!this.isFacetsSelected || !this.notFilteredByVehicle) ? ['\n      all keyword searches, filter selections, and vehicle selections to again browse all products.\n    '] : null
            ] : null)) : null, this.template === 'timeout' ? _createElement('div', {
                'className': 'cmTemplate_timeout',
                'key': '3749'
            }, 'Your search request timed out.\n', _createElement('br', {}), '\nPlease ', _createElement('a', {
                'tabIndex': '0',
                'className': 'cm_message-link',
                'onClick': this.retry
            }, 'try your search again'), '.\n') : null, this.template === 'partial' ? _createElement('div', {
                'className': 'cmTemplate_partial',
                'key': '3968'
            }, 'The system was unable to find products to match your entire search of\n', _createElement('span', { 'className': 'query' }, this.originalQuery), '. A partial search was performed instead.\n') : null, this.template === 'unknown' ? _createElement('div', {
                'className': 'cmTemplate_unknown',
                'key': '4212'
            }, 'Your search request failed to process\n', this.error ? [
                '\n  (',
                this.error.status ? [
                    _createElement('b', { 'key': '43630' }, 'status:'),
                    ' ',
                    this.error.status,
                    ', '
                ] : null,
                this.error.userId ? [
                    _createElement('b', { 'key': '44500' }, 'id:'),
                    ' ',
                    this.error.userId,
                    ', '
                ] : null,
                this.error.timestamp ? [
                    _createElement('b', { 'key': '45330' }, 'timestamp:'),
                    ' ',
                    this.error.timestamp,
                    ', '
                ] : null,
                _createElement('b', { 'key': '43284' }, 'instance:'),
                ' ',
                this.error.instance,
                ')'
            ] : null, '.\n', _createElement('br', {}), '\nPlease ', _createElement('a', {
                'tabIndex': '0',
                'className': 'cm_message-link',
                'onClick': this.retry
            }, 'try your search again'), '.\n') : null);
        }, {
            widgetName: 'undefined',
            items: undefined
        })], [this.customMessage(function () {
            return _createElement('div', { 'className': 'cm_message cm_customMessage' }, []);
        }, {
            widgetName: 'undefined',
            items: undefined
        })], [this.SearchVehicleWidget(function () {
            function repeatSelects1(selects, selectsIndex) {
                return [selects(function () {
                        function repeatEntry1(popularEntries, unpopularEntries, isPopularField, entry, entryIndex) {
                            return _createElement('option', {
                                'disabled': this.loading,
                                'value': entry.term,
                                'key': entry.value
                            }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                        }
                        function repeatEntry2(popularEntries, unpopularEntries, isPopularField, entry, entryIndex) {
                            return _createElement('option', {
                                'disabled': this.loading,
                                'value': entry.term,
                                'key': entry.value
                            }, '\n          ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n        ');
                        }
                        function scopePopularEntriesUnpopularEntriesIsPopularField3() {
                            var popularEntries = this.entries.filter(entry => entry.payload === 'Popular');
                            var unpopularEntries = this.entries.filter(entry => entry.payload !== 'Popular');
                            var isPopularField = popularEntries.length && unpopularEntries.length;
                            return [
                                !(this.hideNullOption && this.entries.some(e => e.selected)) ? _createElement('option', {
                                    'value': '',
                                    'key': 'null-option'
                                }, '\n          ', this.title, '\n        ') : null,
                                this.loading ? _createElement('option', {
                                    'key': 'loading-option',
                                    'disabled': true
                                }, '...loading...') : null,
                                isPopularField ? [
                                    _createElement('option', {
                                        'className': 'cm_option_title',
                                        'disabled': true,
                                        'key': '10541'
                                    }, 'Popular ', this.title === 'Make' ? 'Makes' : this.title === 'Model' ? 'Models' : 'Values'),
                                    _map(popularEntries, repeatEntry1.bind(this, popularEntries, unpopularEntries, isPopularField)),
                                    _createElement('option', {
                                        'className': 'cm_option_title',
                                        'disabled': true,
                                        'key': '10544'
                                    }, 'Additional ', this.title === 'Make' ? 'Makes' : this.title === 'Model' ? 'Models' : 'Values')
                                ] : null,
                                _map(isPopularField ? unpopularEntries : this.entries, repeatEntry2.bind(this, popularEntries, unpopularEntries, isPopularField))
                            ];
                        }
                        function scopeShowSelectedValues4() {
                            var showSelectedValues = this.selectedEntries.length;
                            return _createElement('option', {
                                'key': '_current',
                                'value': '_current'
                            }, showSelectedValues ? [this.selectedEntries.map(e => e.value).join(', ')] : null, !showSelectedValues ? [this.title] : null);
                        }
                        function repeatEntry5(entry, index) {
                            return _createElement('div', {
                                'className': 'option' + (this.isSearchable && this.activeIndex === index || !this.isSearchable && entry.selected ? ' cm_checked' : ''),
                                'key': entry.value,
                                'onClick': () => this.onChange(entry.term)
                            }, this.showCheckboxes ? _createElement('input', {
                                'type': 'checkbox',
                                'readOnly': true,
                                'checked': entry.selected,
                                'className': 'cm_dropdown_checkbox cm_facet_checkbox',
                                'key': '3378'
                            }) : null, _createElement('div', { 'className': 'cm_dropdown_value' }, entry.value), this.showHitCount ? _createElement('div', {
                                'className': 'cm_dropdown_hitCount',
                                'key': '3580'
                            }, entry.hitCount) : null);
                        }
                        return _createElement('div', { 'className': this.extraClassName }, !this.isSearchable ? _createElement('div', {
                            'className': 'cm_select_toggle' + (this.useNativeDropdown ? ' cm_select_toggle__native' : '') + ' cm_toggle-dropdown',
                            'key': '39'
                        }, _createElement('select', {
                            'className': 'cm_select_inner-select cm_select__pretty',
                            'onChange': this.onChange,
                            'value': this.useNativeDropdown ? this.firstSelectedTerm || '' : '_current',
                            'disabled': this.disabled,
                            'aria-label': this.title
                        }, this.useNativeDropdown ? scopePopularEntriesUnpopularEntriesIsPopularField3.apply(this, []) : null, !this.useNativeDropdown ? scopeShowSelectedValues4.apply(this, []) : null)) : null, this.isSearchable ? [[this.filterInput(function () {
                                    return _createElement('div', { 'className': 'cm_filterInput' });
                                }, {
                                    widgetName: 'undefined',
                                    items: undefined
                                })]] : null, !this.disabled && !this.useNativeDropdown ? _createElement.apply(this, [
                            'div',
                            {
                                'className': 'cm_dropdown cm_select_dropdown cm_hide',
                                'aria-label': this.title,
                                'key': '2688'
                            },
                            !this.hideNullOption ? _createElement('div', {
                                'className': 'option',
                                'key': 'null-option',
                                'onClick': () => this.onChange('')
                            }, '\n      ', this.title, '\n    ') : null,
                            this.loading ? _createElement('div', {
                                'className': 'option',
                                'key': 'null-option',
                                'disabled': true
                            }, '...loading...') : null,
                            !this.loading ? _map(this.entries, repeatEntry5.bind(this)) : null
                        ]) : null);
                    }, { count: undefined })];
            }
            return _createElement('div', { 'className': 'cm_vehicle-widget cm_vehicle-widget__search cm_SearchVehicleWidget' }, this.template === 'active' ? _createElement('div', {
                'className': 'cm_vehicle-widget_dropdowns cmTemplate_active',
                'key': '80'
            }, _createElement('div', { 'className': 'cm_vehicle-widget_title' }, window.Convermax.config?.selectYourVehicleTitle || 'Select Your Vehicle'), _createElement('div', { 'className': 'cm_vehicle-widget_body' }, _createElement.apply(this, [
                'div',
                { 'className': 'cm_vehicle-widget_select-container  cmRepeater_selects' },
                _map(this.selects, repeatSelects1.bind(this))
            ]), _createElement('div', { 'className': 'cm_vehicle-widget_button-container' }, _createElement('div', {
                'className': 'cm_vehicle-widget_button cm_vehicle-widget_button__go cm_button cm_button__primary',
                'onClick': this.selectVehicle,
                'disabled': !this.allowToSetVehicle,
                'role': 'button',
                'tabIndex': this.allowToSetVehicle ? '0' : '-1'
            }, '\n    GO\n  '), _createElement('div', {
                'className': 'cm_vehicle-widget_button cm_vehicle-widget_button__clear cm_button cm_button__secondary',
                'onClick': this.discardVehicle,
                'disabled': !this.allowToDiscardVehicle,
                'role': 'button',
                'tabIndex': this.allowToDiscardVehicle ? '0' : '-1'
            }, [_createElement('svg', {
                    'xmlns': 'http://www.w3.org/2000/svg',
                    'className': 'cm_icon cm_icon-reset',
                    'height': '16px',
                    'viewBox': '0 0 16 16',
                    'key': '68320'
                }, _createElement('path', { 'd': 'M2.083,9H0.062H0v5l1.481-1.361C2.932,14.673,5.311,16,8,16c4.08,0,7.446-3.054,7.938-7h-2.021 c-0.476,2.838-2.944,5-5.917,5c-2.106,0-3.96-1.086-5.03-2.729L5.441,9H2.083z' }), _createElement('path', { 'd': 'M8,0C3.92,0,0.554,3.054,0.062,7h2.021C2.559,4.162,5.027,2,8,2c2.169,0,4.07,1.151,5.124,2.876 L11,7h2h0.917h2.021H16V2l-1.432,1.432C13.123,1.357,10.72,0,8,0z' }))])))) : null, this.template === 'locked' ? _createElement('div', {
                'className': 'cm_vehicle-widget_label cmTemplate_locked',
                'key': '7353'
            }, _createElement('div', { 'className': 'cm_vehicle-widget_title' }, '\n  ', this.vehicleString, '\n  ', _createElement('span', { 'className': 'cm_vehicle-widget_subtitle' }, this.fitmentSearchTitle), [this.facetToggle(function () {
                    return _createElement('div', { 'className': 'cm_facetToggle' }, _createElement('span', {
                        'className': 'cm_facet-toggle_label',
                        'onClick': this.toggleFacet
                    }, '\n  Show Universal Parts\n'), _createElement('label', { 'className': 'cm_facet-toggle_switch' }, _createElement('input', {
                        'className': 'cm_facet-toggle_input',
                        'type': 'checkbox',
                        'checked': this.isToggled,
                        'onChange': this.toggleFacet
                    }), _createElement('span', { 'className': 'cm_facet-toggle_slider' })));
                }, {
                    widgetName: 'undefined',
                    items: undefined
                })]), _createElement('div', { 'className': 'cm_vehicle-widget_body' }, _createElement('div', { 'className': 'cm_vehicle-widget_button-container' }, _createElement('div', {
                'className': 'cm_vehicle-widget_button cm_vehicle-widget__change cm_button cm_button__primary',
                'onClick': this.changeVehicle,
                'role': 'button',
                'tabIndex': '0'
            }, '\n      Change\n    '), _createElement('div', {
                'className': 'cm_vehicle-widget_button cm_vehicle-widget__discard cm_button cm_button__secondary',
                'onClick': this.discardVehicle,
                'role': 'button',
                'tabIndex': '0'
            }, '\n      Clear\n    ')))) : null);
        }, {
            widgetName: 'undefined',
            items: undefined
        })], [this.SearchResult(function () {
            function repeatItems1(items, itemsIndex) {
                return [items(function () {
                        function onSubmit1(e) {
                            window.Convermax.addToCart(e);
                        }
                        function mergeProps(inline, external) {
                            var res = Object.assign({}, inline, external);
                            if (inline.hasOwnProperty('style')) {
                                res.style = _.defaults(res.style, inline.style);
                            }
                            if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                                res.className = external.className + ' ' + inline.className;
                            }
                            return res;
                        }
                        function onSubmit3(e) {
                            window.Convermax.addToCart(e);
                        }
                        return _createElement('div', { 'className': 'product__card mb-20 product__card--style_2 product--corner-radius-true color-background-2' }, _createElement('div', { 'className': 'product__card__thumbnail card--client-height' }, _createElement('span', { 'className': 'product__card__badges' }, this.is_preorder ? _createElement('span', {
                            'className': 'badge badge--bottom-left color-inverse',
                            'aria-hidden': 'true',
                            'key': '212'
                        }, '\n        Pre Order\n      ') : null, this.on_sale && this.compare_at_price > this.price ? _createElement('span', {
                            'className': 'badge badge--bottom-left color-inverse',
                            'aria-hidden': 'true',
                            'key': '348'
                        }, _createElement('span', { 'className': 'sale__save--percent' }, '-', this.calcDiscount(this.price, this.compare_at_price), '%'), _createElement('span', { 'className': 'sale__text' }, ' Sale ')) : null), _createElement('a', {
                            'href': this.url,
                            'className': 'd-block product__media_thumbnail product__card--link'
                        }, _createElement('div', { 'className': 'product__card__images media media--transparent media--adapt media--hover-effect' }, _createElement('img', {
                            'className': 'motion-reduce',
                            'src': this.imageOrDefault(this.resizeImage(this.image)),
                            'alt': this.removeHTML(this.title),
                            'loading': 'lazy',
                            'onError': this.onImageError
                        }), this.image2 ? _createElement('img', {
                            'className': 'motion-reduce secondary__img',
                            'src': this.resizeImage(this.image2),
                            'alt': this.removeHTML(this.title),
                            'loading': 'lazy',
                            'onError': e => this.onImageError(e, 'image2'),
                            'key': '1041'
                        }) : null)), _createElement('div', { 'className': 'd-md-only-visible' }, _createElement('wishlist-item', {}, _createElement('button', {
                            'className': 'wishlist__button product__card--wishlist-btn product--tooltip',
                            'type': 'button',
                            'aria-label': 'Add to wishlist',
                            'data-product-handle': this.handle
                        }, _createElement('span', {
                            'title': 'Add to wishlist',
                            'className': 'add__wishlist'
                        }, [_createElement('svg', {
                                'xmlns': 'http://www.w3.org/2000/svg',
                                'viewBox': '0 0 512 512',
                                'key': '15760'
                            }, _createElement('path', {
                                'fill': 'currentColor',
                                'd': 'M462.3 62.7c-54.5-46.4-136-38.7-186.6 13.5L256 96.6l-19.7-20.3C195.5 34.1 113.2 8.7 49.7 62.7c-62.8 53.6-66.1 149.8-9.9 207.8l193.5 199.8c6.2 6.4 14.4 9.7 22.6 9.7 8.2 0 16.4-3.2 22.6-9.7L472 270.5c56.4-58 53.1-154.2-9.7-207.8zm-13.1 185.6L256.4 448.1 62.8 248.3c-38.4-39.6-46.4-115.1 7.7-161.2 54.8-46.8 119.2-12.9 142.8 11.5l42.7 44.1 42.7-44.1c23.2-24 88.2-58 142.8-11.5 54 46 46.1 121.5 7.7 161.2z'
                            }))]), _createElement('span', { 'className': 'loading__wishlist' }), _createElement('span', {
                            'title': 'Remove from wishlist',
                            'className': 'remove__wishlist'
                        }, [_createElement('svg', {
                                'xmlns': 'http://www.w3.org/2000/svg',
                                'viewBox': '0 0 512 512',
                                'key': '22530'
                            }, _createElement('path', {
                                'fill': 'currentColor',
                                'd': 'M417.84 448a15.94 15.94 0 01-11.35-4.72L40.65 75.26a16 16 0 0122.7-22.56l365.83 368a16 16 0 01-11.34 27.3zM364.92 80c-48.09 0-80 29.55-96.92 51-16.88-21.48-48.83-51-96.92-51a107.37 107.37 0 00-31 4.55L168 112c22.26 0 45.81 9 63.94 26.67a123 123 0 0121.75 28.47 16 16 0 0028.6 0 123 123 0 0121.77-28.51C322.19 121 342.66 112 364.92 112c43.15 0 78.62 36.33 79.07 81 .54 53.69-22.75 99.55-57.38 139.52l22.63 22.77c3-3.44 5.7-6.64 8.14-9.6 40-48.75 59.15-98.8 58.61-153C475.37 130.52 425.54 80 364.92 80zM268 432C180.38 372.51 91 297.6 92 193a83.69 83.69 0 012.24-18.39L69 149.14a115.1 115.1 0 00-9 43.49c-.54 54.22 18.63 104.27 58.61 153 18.77 22.87 52.8 59.45 131.39 112.8a31.84 31.84 0 0036 0c20.35-13.81 37.7-26.5 52.58-38.11l-22.66-22.81C300.25 409.6 284.09 421.05 268 432z'
                            }))]), _createElement('div', { 'className': 'product--tooltip-label tooltip--left' }, _createElement('span', { 'className': 'product__card--add-wishlist' }, 'Add to wishlist'), _createElement('span', { 'className': 'product__card--remove-wishlist' }, ' Remove from wishlist '))))), _createElement('div', { 'className': 'product__cart--wrapper product__card--style2 d-md-none' }, _createElement('product-form', {}, _createElement('form', {
                            'onSubmit': onSubmit1.bind(this),
                            'action': '/cart/add',
                            'method': 'post',
                            'encType': 'multipart/form-data',
                            'data-type': 'add-to-cart-form'
                        }, _createElement('input', {
                            'type': 'hidden',
                            'name': 'id',
                            'value': this.variant_ids[0]
                        }), _createElement('button', {
                            'type': 'submit',
                            'name': 'add',
                            'className': 'product__card--action-btn product__card--cart-btn button product__card--style2'
                        }, _createElement('span', { 'className': 'action__btn--svg' }, [_createElement('svg', {
                                'viewBox': '0 0 256 256',
                                'xmlns': 'http://www.w3.org/2000/svg',
                                'fill': 'currentColor',
                                'key': '39840'
                            }, _createElement('rect', {
                                'fill': 'none',
                                'height': '256',
                                'width': '256'
                            }), _createElement('circle', {
                                'cx': '80',
                                'cy': '216',
                                'r': '16'
                            }), _createElement('circle', {
                                'cx': '184',
                                'cy': '216',
                                'r': '16'
                            }), _createElement('path', {
                                'd': 'M42.3,72H221.7l-26.4,92.4A15.9,15.9,0,0,1,179.9,176H84.1a15.9,15.9,0,0,1-15.4-11.6L32.5,37.8A8,8,0,0,0,24.8,32H8',
                                'fill': 'none',
                                'stroke': 'currentColor',
                                'strokeLinecap': 'round',
                                'strokeLinejoin': 'round',
                                'strokeWidth': '16'
                            }))]), _createElement('span', { 'className': 'cart__buton--label' }, 'Add to cart'))))), _createElement('div', { 'className': 'product-card-action-buttons-style2 d-md-none' }, _createElement('wishlist-item', {}, _createElement('button', {
                            'className': 'wishlist__button product__card-style2--action-btn product--tooltip',
                            'type': 'button',
                            'aria-label': 'Add to wishlist',
                            'data-product-handle': this.handle
                        }, _createElement('span', {
                            'title': 'Add to wishlist',
                            'className': 'add__wishlist'
                        }, [_createElement('svg', {
                                'xmlns': 'http://www.w3.org/2000/svg',
                                'viewBox': '0 0 512 512',
                                'key': '49270'
                            }, _createElement('path', {
                                'fill': 'currentColor',
                                'd': 'M462.3 62.7c-54.5-46.4-136-38.7-186.6 13.5L256 96.6l-19.7-20.3C195.5 34.1 113.2 8.7 49.7 62.7c-62.8 53.6-66.1 149.8-9.9 207.8l193.5 199.8c6.2 6.4 14.4 9.7 22.6 9.7 8.2 0 16.4-3.2 22.6-9.7L472 270.5c56.4-58 53.1-154.2-9.7-207.8zm-13.1 185.6L256.4 448.1 62.8 248.3c-38.4-39.6-46.4-115.1 7.7-161.2 54.8-46.8 119.2-12.9 142.8 11.5l42.7 44.1 42.7-44.1c23.2-24 88.2-58 142.8-11.5 54 46 46.1 121.5 7.7 161.2z'
                            }))]), _createElement('span', { 'className': 'loading__wishlist' }), _createElement('span', {
                            'title': 'Remove from wishlist',
                            'className': 'remove__wishlist'
                        }, [_createElement('svg', {
                                'xmlns': 'http://www.w3.org/2000/svg',
                                'viewBox': '0 0 512 512',
                                'key': '56040'
                            }, _createElement('path', {
                                'fill': 'currentColor',
                                'd': 'M417.84 448a15.94 15.94 0 01-11.35-4.72L40.65 75.26a16 16 0 0122.7-22.56l365.83 368a16 16 0 01-11.34 27.3zM364.92 80c-48.09 0-80 29.55-96.92 51-16.88-21.48-48.83-51-96.92-51a107.37 107.37 0 00-31 4.55L168 112c22.26 0 45.81 9 63.94 26.67a123 123 0 0121.75 28.47 16 16 0 0028.6 0 123 123 0 0121.77-28.51C322.19 121 342.66 112 364.92 112c43.15 0 78.62 36.33 79.07 81 .54 53.69-22.75 99.55-57.38 139.52l22.63 22.77c3-3.44 5.7-6.64 8.14-9.6 40-48.75 59.15-98.8 58.61-153C475.37 130.52 425.54 80 364.92 80zM268 432C180.38 372.51 91 297.6 92 193a83.69 83.69 0 012.24-18.39L69 149.14a115.1 115.1 0 00-9 43.49c-.54 54.22 18.63 104.27 58.61 153 18.77 22.87 52.8 59.45 131.39 112.8a31.84 31.84 0 0036 0c20.35-13.81 37.7-26.5 52.58-38.11l-22.66-22.81C300.25 409.6 284.09 421.05 268 432z'
                            }))]), _createElement('div', { 'className': 'product--tooltip-label tooltip--left' }, _createElement('span', { 'className': 'product__card--add-wishlist' }, 'Add to wishlist'), _createElement('span', { 'className': 'product__card--remove-wishlist' }, ' Remove from wishlist ')))), _createElement('compare-item', {}, _createElement('button', {
                            'className': 'compare__button wishlist__button product__card-style2--action-btn product--tooltip',
                            'type': 'button',
                            'aria-label': 'Add to compare',
                            'data-product-handle': this.handle,
                            'data-product-title': this.removeHTML(this.title)
                        }, _createElement('span', {
                            'title': 'Add to compare',
                            'className': 'add__wishlist'
                        }, [_createElement('svg', {
                                'xmlns': 'http://www.w3.org/2000/svg',
                                'className': 'icon icon-tabler icon-tabler-switch',
                                'width': '44',
                                'height': '44',
                                'viewBox': '0 0 24 24',
                                'strokeWidth': '1.7',
                                'stroke': 'currentColor',
                                'fill': 'none',
                                'strokeLinecap': 'round',
                                'strokeLinejoin': 'round',
                                'key': '71420'
                            }, _createElement('path', {
                                'stroke': 'none',
                                'd': 'M0 0h24v24H0z',
                                'fill': 'none'
                            }), _createElement('polyline', { 'points': '15 4 19 4 19 8' }), _createElement('line', {
                                'x1': '14.75',
                                'y1': '9.25',
                                'x2': '19',
                                'y2': '4'
                            }), _createElement('line', {
                                'x1': '5',
                                'y1': '19',
                                'x2': '9',
                                'y2': '15'
                            }), _createElement('polyline', { 'points': '15 19 19 19 19 15' }), _createElement('line', {
                                'x1': '5',
                                'y1': '5',
                                'x2': '19',
                                'y2': '19'
                            }))]), _createElement('span', { 'className': 'loading__wishlist' }), _createElement('span', {
                            'title': 'Remove from compare',
                            'className': 'remove__wishlist'
                        }, [_createElement('svg', {
                                'xmlns': 'http://www.w3.org/2000/svg',
                                'width': '24',
                                'height': '24',
                                'viewBox': '0 0 24 24',
                                'fill': 'none',
                                'stroke': 'currentColor',
                                'strokeWidth': '1.5',
                                'strokeLinecap': 'round',
                                'strokeLinejoin': 'round',
                                'className': 'feather feather-check-circle',
                                'key': '78030'
                            }, _createElement('path', { 'd': 'M22 11.08V12a10 10 0 1 1-5.93-9.14' }), _createElement('polyline', { 'points': '22 4 12 14.01 9 11.01' }))]), _createElement('div', { 'className': 'product--tooltip-label tooltip--left' }, _createElement('span', { 'className': 'product__card--add-wishlist' }, 'Add to compare'), _createElement('span', { 'className': 'product__card--remove-wishlist' }, 'Remove from compare')))), _createElement('quick-view-modal', {}, _createElement('button', {
                            'aria-haspopup': 'dialog',
                            'type': 'button',
                            'className': 'product__quick_view product__card-style2--action-btn product--tooltip',
                            'data-product-handle': this.handle,
                            'aria-label': 'quick view'
                        }, _createElement('span', { 'className': 'action__btn--svg' }, [_createElement('svg', {
                                'xmlns': 'http://www.w3.org/2000/svg',
                                'width': '24',
                                'height': '24',
                                'viewBox': '0 0 24 24',
                                'fill': 'none',
                                'stroke': 'currentColor',
                                'strokeWidth': '2',
                                'strokeLinecap': 'round',
                                'strokeLinejoin': 'round',
                                'className': 'feather feather-eye',
                                'key': '87150'
                            }, _createElement('path', { 'd': 'M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z' }), _createElement('circle', {
                                'cx': '12',
                                'cy': '12',
                                'r': '3'
                            }))]), _createElement('div', { 'className': 'product--tooltip-label tooltip--left' }, 'Quick view'))))), _createElement('div', { 'className': 'product__card__content text-left product--card-spacing-true' }, _createElement('h3', { 'className': 'product__card__title h6' }, _createElement('a', mergeProps({
                            'className': 'product__card-title--link',
                            'href': this.url
                        }, { dangerouslySetInnerHTML: { __html: this.title } }))), _createElement('div', { 'className': 'price product__card__price justify-content-start' + (this.on_sale ? ' price--on-sale' : '') }, _createElement('dl', {}, _createElement('div', { 'className': 'price__regular' }, _createElement('dd', {}, _createElement('span', { 'className': 'price-item price-item--regular' }, this.price_varies ? _createElement('span', { 'key': '9718' }, 'From: ') : null, ' ', this.formatPrice(this.price), '\n            '))), this.on_sale && this.compare_at_price > this.price ? _createElement('div', {
                            'className': 'price__sale',
                            'key': '9854'
                        }, _createElement('dd', { 'className': 'price__compare' }, _createElement('s', { 'className': 'price-item price-item--regular' }, ' ', this.formatPrice(this.compare_at_price), ' ')), _createElement('dd', {}, _createElement('span', { 'className': 'price-item price-item--sale' }, ' ', this.formatPrice(this.price), ' '))) : null)), _createElement('div', { 'className': 'product-card-action-buttons d-md-only-visible' }, _createElement('div', { 'className': 'product__cart--wrapper' }, _createElement('product-form', {}, _createElement('form', {
                            'onSubmit': onSubmit3.bind(this),
                            'action': '/cart/add',
                            'method': 'post',
                            'encType': 'multipart/form-data',
                            'data-type': 'add-to-cart-form'
                        }, _createElement('input', {
                            'type': 'hidden',
                            'name': 'id',
                            'value': this.variant_ids[0]
                        }), _createElement('button', {
                            'type': 'submit',
                            'name': 'add',
                            'className': 'product__card--action-btn product__card--cart-btn product--tooltip'
                        }, _createElement('span', { 'className': 'action__btn--svg' }, [_createElement('svg', {
                                'viewBox': '0 0 256 256',
                                'xmlns': 'http://www.w3.org/2000/svg',
                                'fill': 'currentColor',
                                'key': '108170'
                            }, _createElement('rect', {
                                'fill': 'none',
                                'height': '256',
                                'width': '256'
                            }), _createElement('circle', {
                                'cx': '80',
                                'cy': '216',
                                'r': '16'
                            }), _createElement('circle', {
                                'cx': '184',
                                'cy': '216',
                                'r': '16'
                            }), _createElement('path', {
                                'd': 'M42.3,72H221.7l-26.4,92.4A15.9,15.9,0,0,1,179.9,176H84.1a15.9,15.9,0,0,1-15.4-11.6L32.5,37.8A8,8,0,0,0,24.8,32H8',
                                'fill': 'none',
                                'stroke': 'currentColor',
                                'strokeLinecap': 'round',
                                'strokeLinejoin': 'round',
                                'strokeWidth': '16'
                            }))]), _createElement('span', { 'className': 'cart__buton--label' }, 'Add to cart '), _createElement('div', { 'className': 'product--tooltip-label tooltip--top desktop--tooltip-disable' }, 'Add to cart'))))), _createElement('compare-item', {}, _createElement('button', {
                            'className': 'compare__button wishlist__button product__card--action-btn product__card--action-btn-icon product--tooltip',
                            'type': 'button',
                            'aria-label': 'Add to compare',
                            'data-product-handle': this.handle,
                            'data-product-title': this.removeHTML(this.title)
                        }, _createElement('span', {
                            'title': 'Add to compare',
                            'className': 'add__wishlist'
                        }, [_createElement('svg', {
                                'xmlns': 'http://www.w3.org/2000/svg',
                                'className': 'icon icon-tabler icon-tabler-switch',
                                'width': '44',
                                'height': '44',
                                'viewBox': '0 0 24 24',
                                'strokeWidth': '1.7',
                                'stroke': 'currentColor',
                                'fill': 'none',
                                'strokeLinecap': 'round',
                                'strokeLinejoin': 'round',
                                'key': '119040'
                            }, _createElement('path', {
                                'stroke': 'none',
                                'd': 'M0 0h24v24H0z',
                                'fill': 'none'
                            }), _createElement('polyline', { 'points': '15 4 19 4 19 8' }), _createElement('line', {
                                'x1': '14.75',
                                'y1': '9.25',
                                'x2': '19',
                                'y2': '4'
                            }), _createElement('line', {
                                'x1': '5',
                                'y1': '19',
                                'x2': '9',
                                'y2': '15'
                            }), _createElement('polyline', { 'points': '15 19 19 19 19 15' }), _createElement('line', {
                                'x1': '5',
                                'y1': '5',
                                'x2': '19',
                                'y2': '19'
                            }))]), _createElement('span', { 'className': 'loading__wishlist' }), _createElement('span', {
                            'title': 'Remove from compare',
                            'className': 'remove__wishlist'
                        }, [_createElement('svg', {
                                'xmlns': 'http://www.w3.org/2000/svg',
                                'width': '24',
                                'height': '24',
                                'viewBox': '0 0 24 24',
                                'fill': 'none',
                                'stroke': 'currentColor',
                                'strokeWidth': '1.5',
                                'strokeLinecap': 'round',
                                'strokeLinejoin': 'round',
                                'className': 'feather feather-check-circle',
                                'key': '125650'
                            }, _createElement('path', { 'd': 'M22 11.08V12a10 10 0 1 1-5.93-9.14' }), _createElement('polyline', { 'points': '22 4 12 14.01 9 11.01' }))]), _createElement('div', { 'className': 'product--tooltip-label tooltip--top' }, _createElement('span', { 'className': 'product__card--add-wishlist' }, 'Add to compare'), _createElement('span', { 'className': 'product__card--remove-wishlist' }, 'Remove from compare')))), _createElement('quick-view-modal', {}, _createElement('button', {
                            'aria-haspopup': 'dialog',
                            'type': 'button',
                            'className': 'product__quick_view product__card--action-btn product__card--action-btn-icon product--tooltip',
                            'data-product-handle': this.handle,
                            'aria-label': 'quick view'
                        }, _createElement('span', { 'className': 'action__btn--svg' }, [_createElement('svg', {
                                'xmlns': 'http://www.w3.org/2000/svg',
                                'width': '24',
                                'height': '24',
                                'viewBox': '0 0 24 24',
                                'fill': 'none',
                                'stroke': 'currentColor',
                                'strokeWidth': '2',
                                'strokeLinecap': 'round',
                                'strokeLinejoin': 'round',
                                'className': 'feather feather-eye',
                                'key': '135000'
                            }, _createElement('path', { 'd': 'M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z' }), _createElement('circle', {
                                'cx': '12',
                                'cy': '12',
                                'r': '3'
                            }))]), _createElement('div', { 'className': 'product--tooltip-label tooltip--top' }, 'Quick view'))))));
                    }, { count: undefined })];
            }
            return _createElement('div', { 'className': 'cm_SearchResult' }, _createElement.apply(this, [
                'div',
                { 'className': (this.view ? ` ${ this.view }` : '') + ' cmRepeater_items' },
                _map(this.items, repeatItems1.bind(this))
            ]), [this.message(function () {
                    return _createElement('div', { 'className': 'cm_message' }, this.hasMessage('TrialExpired') ? [_createElement('div', { 'key': '240' }, _createElement('h2', { 'className': 'cm_message-header' }, 'YMM Search trial period has expired.'), '\n  Product information, including pricing and fitments, is outdated. This store runs on a limited trial version\n  of Convermax Year Make Model fitment search. Please contact\n  ', _createElement('a', {
                            'href': 'mailto:support@convermax.com',
                            'className': 'cm_message-link'
                        }, 'support@convermax.com'), ' to complete setup\n  or cancel the integration.\n')] : null, this.hasMessage('AggressiveWheels') ? [_createElement('div', { 'key': '4980' }, '\n  Warning: Aggressive wheel options are displayed, but we cannot guarantee they will fit your selected\n  vehicle.\n')] : null, this.hasMessage('IncompatibleParts') && !this.notFilteredByVehicle ? [_createElement('div', {
                            'className': 'cm_message__incompatible',
                            'key': '7050'
                        }, _createElement('div', {}, '\n    The products shown below will not fit a ', _createElement('span', {}, this.vehicleString), '.', _createElement('br', {}), '\n    The products results are based on your keyword search only.\n  '))] : null, this.isNoVehicleSpecificResults && this.isVehicleSelected && !this.notFilteredByVehicle && !this.hasMessage('NonVehicleProducts') ? [_createElement('div', {
                            'className': 'cm_message__universal',
                            'key': '10350'
                        }, '\n  The system was unable to find any vehicle specific products that fit a\n  ', _createElement('span', {}, '\'', this.vehicleString, '\'.'), _createElement('br', {}), '\n  Click ', _createElement('a', {
                            'tabIndex': '0',
                            'className': 'cm_message-link',
                            'href': this.searchByVehicleUrl
                        }, 'here'), ' to clear your vehicle\n  selection and browse all products in the category.\n')] : null, this.template === 'badRequest' ? _createElement('div', {
                        'className': 'cmTemplate_badRequest',
                        'key': '1568'
                    }, 'Your request failed to process (', _createElement('b', {}, 'status:'), ' ', this.error.status, ', ', _createElement('b', {}, 'id:'), ' ', this.error.userId, ',\n', _createElement('b', {}, 'timestamp:'), ' ', this.error.timestamp, ', ', _createElement('b', {}, 'instance:'), ' ', this.error.instance, ').\n', _createElement('br', {}), '\nPlease ', _createElement('a', {
                        'tabIndex': '0',
                        'className': 'cm_message-link',
                        'onClick': this.startOver
                    }, 'clear your search'), ' and try\nsearching for something else.\n') : null, this.template === 'corrected' ? _createElement('div', {
                        'className': 'cmTemplate_corrected',
                        'key': '1982'
                    }, 'Your search for ', _createElement('span', { 'className': 'query' }, this.originalQuery), '\ndid not match any products. The system searched for\n', _createElement('span', { 'className': 'query' }, this.query), ' instead.\n') : null, this.template === 'networkError' ? _createElement('div', {
                        'className': 'cmTemplate_networkError',
                        'key': '2236'
                    }, 'Network error\n', this.error ? [
                        '(id: ',
                        this.error.userId,
                        ', timestamp: ',
                        this.error.timestamp,
                        ', instance: ',
                        this.error.instance,
                        ')'
                    ] : null, '.\n', _createElement('br', {}), '\nThe search server could not be reached. Please try again later.\n') : null, this.template === 'nothing' ? _createElement('div', {
                        'className': 'cmTemplate_nothing',
                        'key': '2554'
                    }, _createElement('div', {}, this.pageType !== 'search' && (!this.isFacetsSelected || this.notFilteredByVehicle) ? [
                        '\n    This ',
                        this.pageType,
                        ' doesn\'t contain any\n    ',
                        !!this.vehicleString && !this.notFilteredByVehicle ? [
                            '\n      products that fit a ',
                            _createElement('span', { 'key': '28171' }, '\'', this.vehicleString, '\'.')
                        ] : null,
                        !this.vehicleString || this.notFilteredByVehicle ? [' products.'] : null
                    ] : null, this.pageType === 'search' ? [_createElement('div', { 'key': '30991' }, 'No results were found for your search.')] : null, this.query || !!this.vehicleString && !this.notFilteredByVehicle ? [
                        '\n    Click ',
                        _createElement('a', {
                            'tabIndex': '0',
                            'className': 'cm_message-link',
                            'onClick': this.startOver,
                            'key': '32301'
                        }, 'here'),
                        ' to clear\n    ',
                        !this.vehicleString ? [' selection. '] : null,
                        this.vehicleString && (!this.isFacetsSelected || !this.notFilteredByVehicle) ? ['\n      all keyword searches, filter selections, and vehicle selections to again browse all products.\n    '] : null
                    ] : null)) : null, this.template === 'timeout' ? _createElement('div', {
                        'className': 'cmTemplate_timeout',
                        'key': '3749'
                    }, 'Your search request timed out.\n', _createElement('br', {}), '\nPlease ', _createElement('a', {
                        'tabIndex': '0',
                        'className': 'cm_message-link',
                        'onClick': this.retry
                    }, 'try your search again'), '.\n') : null, this.template === 'partial' ? _createElement('div', {
                        'className': 'cmTemplate_partial',
                        'key': '3968'
                    }, 'The system was unable to find products to match your entire search of\n', _createElement('span', { 'className': 'query' }, this.originalQuery), '. A partial search was performed instead.\n') : null, this.template === 'unknown' ? _createElement('div', {
                        'className': 'cmTemplate_unknown',
                        'key': '4212'
                    }, 'Your search request failed to process\n', this.error ? [
                        '\n  (',
                        this.error.status ? [
                            _createElement('b', { 'key': '43630' }, 'status:'),
                            ' ',
                            this.error.status,
                            ', '
                        ] : null,
                        this.error.userId ? [
                            _createElement('b', { 'key': '44500' }, 'id:'),
                            ' ',
                            this.error.userId,
                            ', '
                        ] : null,
                        this.error.timestamp ? [
                            _createElement('b', { 'key': '45330' }, 'timestamp:'),
                            ' ',
                            this.error.timestamp,
                            ', '
                        ] : null,
                        _createElement('b', { 'key': '43284' }, 'instance:'),
                        ' ',
                        this.error.instance,
                        ')'
                    ] : null, '.\n', _createElement('br', {}), '\nPlease ', _createElement('a', {
                        'tabIndex': '0',
                        'className': 'cm_message-link',
                        'onClick': this.retry
                    }, 'try your search again'), '.\n') : null);
                }, {
                    widgetName: 'undefined',
                    items: undefined
                })]);
        }, {
            widgetName: 'results',
            items: undefined
        })], [this.pagination(function () {
            function repeatButtons1(buttons, buttonsIndex) {
                return [buttons(function () {
                        return this.template === 'prev' ? _createElement('li', { 'className': 'cmTemplate_prev' }, _createElement('a', {
                            'href': this.pageLink,
                            'className': 'btn btn--narrow btn--prev btn--pagination',
                            'aria-label': 'link'
                        }, [_createElement('svg', {
                                'aria-hidden': 'true',
                                'focusable': 'false',
                                'role': 'presentation',
                                'className': 'icon icon-chevron-left',
                                'viewBox': '0 0 370.814 370.814',
                                'key': '1340'
                            }, _createElement('g', {}, _createElement('g', {}, _createElement('polygon', { 'points': '292.92,24.848 268.781,0 77.895,185.401 268.781,370.814 292.92,345.961 127.638,185.401' }))))])) : this.template === 'next' ? _createElement('li', { 'className': 'cmTemplate_next' }, _createElement('a', {
                            'href': this.pageLink,
                            'className': 'btn btn--narrow btn--next btn--pagination',
                            'aria-label': 'link'
                        }, [_createElement('svg', {
                                'aria-hidden': 'true',
                                'focusable': 'false',
                                'role': 'presentation',
                                'className': 'icon icon-chevron-right',
                                'viewBox': '0 0 478.448 478.448',
                                'key': '1340'
                            }, _createElement('g', {}, _createElement('g', {}, _createElement('polygon', { 'points': '131.659,0 100.494,32.035 313.804,239.232 100.494,446.373 131.65,478.448 377.954,239.232' }))))])) : this.template === 'page' ? _createElement('li', { 'className': (this.isActive ? 'active' : '') + ' cmTemplate_page' }, this.isActive ? _createElement('span', {
                            'className': 'pagination-link pagination--current',
                            'key': '85'
                        }, this.page) : null, !this.isActive ? _createElement('a', {
                            'href': this.pageLink,
                            'className': 'pagination-link',
                            'aria-label': 'link',
                            'key': '180'
                        }, ' ', this.page, ' ') : null) : _createElement('div', { 'className': 'cmTemplate_Unknow' }, JSON.stringify(this));
                    }, { count: undefined })];
            }
            return _createElement('div', { 'className': 'cm_pagination' }, _createElement.apply(this, [
                'ul',
                { 'className': 'list--inline pagination cmRepeater_buttons' },
                _map(this.buttons, repeatButtons1.bind(this))
            ]));
        }, {
            widgetName: 'undefined',
            items: undefined
        })])));
}
        export const componentNames = ["cm:dialogButton","cm:facetDialogButton","cm:SearchHeader","cm:filterInput","cm:VehicleSpecs","cm:filterChips","cm:FilterChips","cm:filterInput","cm:FacetBarWheels","cm:filterInput","cm:FacetBarTires","cm:filterInput","cm:filterInput","cm:select","cm:inputMin","cm:inputMax","cm:Inputs","cm:inputMin","cm:inputMax","cm:Inputs","cm:facets","cm:Facets","cm:message","cm:customMessage","cm:filterInput","cm:facetToggle","cm:SearchVehicleWidget","cm:message","cm:SearchResult","cm:pagination"]