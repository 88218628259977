export default {
  GARAGE_CLASS: 'header__actions_btn',
  GARAGE_SIZE: '<span class="garage-size header__actions_btn_cart_num">{{size}}</span>',

  FACET_TITLE_ICON: `
  <rt-virtual rt-if="this.isCollapsed" template="icons/angle-down" />
  <rt-virtual rt-if="!this.isCollapsed" template="icons/angle-up" />`,

  SORT_BY: 'Sort By',

  TOTAL_HITS: 'results',

  CURRENT_SEARCH: 'Filter:',
  START_OVER: 'Remove all',
};
